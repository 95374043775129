export const jsonFetch = async (...params: Parameters<typeof fetch>) => {
  const response = await fetch(...params);
  if (!response.ok) throw new Error('network error');

  return response.json();
};

export default {
  jsonFetch,
};

/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useState } from 'react';
import { strings } from '../utils/Localization/LocalizationStrings';
import BaseButton from './BaseButton';
import BaseInput from './BaseInput';
import SelectBox from './SelectBox';
import backIcon from '../assets/img/icn_back.svg';

interface SearchSideBarProps {
  priceProps?: {
    max: number | null;
    min: number | null;
    priceHandler: (prices: { max: number | null; min: number | null }) => void;
  };
  categoryProps?: {
    categoryList: string[];
    selectedCategories: string[];
    categoryHandler: (checked: boolean, id: string) => void;
  };
  osProps?: {
    osList: string[];
    selectedOs: string[];
    osHandler: (checked: boolean, id: string) => void;
  };
  closeOnFullscreen: () => void;
}

function SearchSideBar({
  closeOnFullscreen,
  priceProps,
  categoryProps,
  osProps,
}: SearchSideBarProps) {
  const [selectedOption, setSelectOption] = useState('Ethereum');

  useEffect(() => {
    if (window.matchMedia('not all and (min-width: 768px)').matches)
      document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <aside className="max-md:fixed max-md:top-0 max-md:left-0 max-md:bg-white md:py-[58px] max-md:z-[10001] w-full h-full md:px-2 overflow-auto">
      <div className="mt-0 md:mt-6 py-5 md:hidden relative text-center">
        <button onClick={closeOnFullscreen} className="absolute left-5 top-1/2 -translate-y-1/2">
          <img src={backIcon} alt={strings.GoBackIcon} />
        </button>
        <span className=" text-base font-bold">{strings.searchSidebar_FillterText}</span>
      </div>

      <div className="max-md:px-4 max-md:pb-4">
        {priceProps && (
          <>
            <hr className=" border-[#E9EAEC] " />
            <h2 className="pt-[24px] md:border-t  border-[#E9EAEC] text-[16px] font-semibold text-left">
              {strings.searchSidebar_PriceText}
            </h2>
            <form
              className="py-[24px] w-full pl-[10px] border-[#E9EAEC]"
              onSubmit={(e) => {
                e.preventDefault();
                const { max, min } = e.currentTarget;
                const convertToNumber = (value: string) =>
                  Number.isNaN(+value) || value.length < 1 ? null : +value;

                priceProps.priceHandler({
                  min: convertToNumber(min.value),
                  max: convertToNumber(max.value),
                });
              }}
            >
              <div className="max-md:flex max-md:flex-row max-md:w-full max-md:mt-auto max-md:gap-1 max-md:items-center">
                <BaseInput
                  type="number"
                  name="min"
                  step="any"
                  className="md:w-[170px] h-[44px] md:mr-[13px] py-[12px] pr-[17px] text-[14px] text-right placeholder:text-black border border-[#9CA4AC]"
                />
                ~
                <BaseInput
                  type="number"
                  name="max"
                  step="any"
                  className="md:w-[170px] h-[44px] md:mt-[20px] py-[12px] pr-[17px] text-[14px] text-right placeholder:text-black border border-[#9CA4AC]"
                />
              </div>
              <div className="relative max-md:w-1/2 max-md:p-1 max-md:mt-1 mt-[10px]">
                <SelectBox
                  options={['Ethereum']}
                  selectedOption={selectedOption}
                  textAlign="text-left"
                  disabled="pointer-events-none"
                  setSelectOption={setSelectOption}
                />
              </div>
              <BaseButton
                type="submit"
                className="w-full h-[34px] mt-[10px] bg-[#2ACFBB] text-[13px] font-medium text-white rounded-[0px] max-md:w-full max-md:rounded"
              >
                {strings.searchSidebar_Button}
              </BaseButton>
            </form>
          </>
        )}

        {categoryProps && (
          <>
            <hr className=" border-[#E9EAEC] " />
            <h2 className="mt-[24px] text-[16px] font-semibold">
              {strings.searchSidebar_CategoryText}
            </h2>
            <div className="flex flex-col gap-[20px] pt-[24px] pb-[20px] px-[13px] border-b border-[#E9EAEC]">
              {categoryProps.categoryList.map((item) => {
                return (
                  <div className="flex items-center" key={item}>
                    <input
                      type="checkbox"
                      id={item}
                      className="w-[18px] h-[18px] mr-[13px] filterCheck"
                      onChange={(e) => categoryProps.categoryHandler(e.currentTarget.checked, item)}
                      checked={categoryProps.selectedCategories.includes(item)}
                    />
                    <label htmlFor={item} className="text-[13px] font-medium flex">
                      {item}
                    </label>
                  </div>
                );
              })}
            </div>
          </>
        )}

        {osProps && (
          <>
            <h2 className="mt-[24px] text-[16px] font-semibold">{strings.searchSidebar_OsText}</h2>
            <div className="flex flex-col gap-[20px] mt-[27px] px-[13px]">
              {osProps.osList.map((item) => {
                return (
                  <div className="flex items-center" key={item}>
                    <input
                      type="checkbox"
                      id={item}
                      className={`${
                        item === 'IOS' ? 'opacity-25' : ''
                      } w-[18px] h-[18px] mr-[13px] filterCheck`}
                      onChange={(e) => {
                        if (!(item === 'IOS')) osProps.osHandler(e.currentTarget.checked, item);
                      }}
                      checked={osProps.selectedOs.includes(item)}
                    />
                    <label
                      htmlFor={item}
                      className={`${item === 'IOS' ? 'opacity-25' : ''} text-[13px] font-medium `}
                    >
                      {item}
                    </label>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </aside>
  );
}

export default SearchSideBar;

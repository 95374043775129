import { useCallback, useMemo, useState } from 'react';
import { strings } from '../utils/Localization/LocalizationStrings';
import { WatchFaceItem, WatchFaceNFT } from '../types';

export const CategoryCheckBoxDefaultInputs = ['Analog', 'Digital', 'Digilog', 'Informative'];
export const OsCheckBoxDefaultInputs = ['Android', 'IOS'];

export const sortOptions = [
  { text: strings.Newest, value: 'newest' },
  { text: strings.PriceHigh, value: 'high' },
  { text: strings.PriceLowest, value: 'low' },
] as const;

export const sortValues = sortOptions.map((el) => el.value);
export const sortTexts = sortOptions.map((el) => el.text);

export function useWatchfaceFilter<WatchType extends WatchFaceNFT | WatchFaceItem>() {
  const [min, setMin] = useState<number | null>(null);
  const [max, setMax] = useState<number | null>(null);
  const [sort, setSort] = useState<(typeof sortValues)[number]>('newest');
  const [searchKeyword, setSearchKeyword] = useState('');
  const [selectedCategories, setSelectedCategories] = useState<string[]>(
    CategoryCheckBoxDefaultInputs
  );
  const [selectedOs, setSelectedOs] = useState(
    OsCheckBoxDefaultInputs.filter((el) => el !== 'IOS')
  );

  const states = useMemo(
    () => ({
      min,
      max,
      sort,
      searchKeyword,
      selectedCategories,
      selectedOs,
    }),
    [min, max, sort, searchKeyword, selectedCategories, selectedOs]
  );

  const setters = {
    min: setMin,
    max: setMax,
    sort: setSort,
    searchKeyword: setSearchKeyword,
    selectedCategories: setSelectedCategories,
    selectedOs: setSelectedOs,
  } as const;

  const getSetter = <Key extends keyof typeof setters>(key: Key): (typeof setters)[Key] =>
    setters[key];

  const filterWatchFace = useCallback(
    (watchFaces: WatchType[]) => {
      return watchFaces
        .filter(
          (watchFace) =>
            'appName' in watchFace ||
            (min == null && max == null) ||
            (watchFace.price &&
              watchFace.price >= (min ?? 0) &&
              watchFace.price <= (max ?? Infinity))
        )
        .filter(({ category }) =>
          selectedCategories
            .map((e) => e.toLowerCase())
            .includes(
              typeof category === 'string' ? category.toLowerCase() : category.name.toLowerCase()
            )
        )
        .filter((watchFace) =>
          selectedOs
            .map((e) => e.toLowerCase())
            .includes(watchFace.model.builder.description.osType.toLowerCase())
        )
        .filter(
          (watchFace) =>
            !searchKeyword ||
            ('title' in watchFace
              ? watchFace.title.toLowerCase()
              : watchFace.appName.toLowerCase()
            ).includes(searchKeyword.toLowerCase()) ||
            watchFace.description?.toLowerCase().includes(searchKeyword.toLowerCase())
        );
    },
    [states]
  );

  const sortFunction = {
    newest: (a: WatchType, b: WatchType) => +new Date(b.createdAt) - +new Date(a.createdAt),
    high: (a: WatchType, b: WatchType) => {
      if (!('price' in b)) return -1;
      if (b.price == null) return -1;
      if (!('price' in a)) return 1;
      if (a.price == null) return 1;
      return b.price - a.price;
    },
    low: (a: WatchType, b: WatchType) => {
      if (!('price' in b)) return -1;
      if (b.price == null) return -1;
      if (!('price' in a)) return 1;
      if (a.price == null) return 1;
      return a.price - b.price;
    },
  } as const;

  const sortWatchFace = useCallback(
    (watchFaces: WatchType[]) => watchFaces.sort(sortFunction[sort]),
    [sort]
  );

  return { states, getSetter, filterWatchFace, sortWatchFace };
}

import { ReactNode } from 'react';

function SocialLogin({ children, authURL }: { children: ReactNode | undefined; authURL: string }) {
  const Login = () => {
    window.location.href = authURL;
  };

  return (
    <button onClick={Login} className="inline-block w-[61.2px] h-[61.2px] cursor-pointer">
      {children}
    </button>
  );
}

export default SocialLogin;

import { useHistory } from 'react-router-dom';
import { strings, lang } from '../utils/Localization/LocalizationStrings';
import watch from '../assets/img/watch_img 1.png';
import speechBubble from '../assets/img/Ellipse 497.png';
import text404 from '../assets/img/404.png';
import speechBubblePolygon from '../assets/img/Polygon 2.png';
import vector95 from '../assets/img/Vector 95.png';
import vector96 from '../assets/img/Vector 96.png';
import vector97 from '../assets/img/Vector 97.png';
import Ellipse from '../assets/img/Ellipse 496.svg';

function Error() {
  const history = useHistory();
  return (
    <div className="w-full h-screen text-center justify-center flex flex-1 flex-col gap-3 px-2 py-8 m-0">
      <div className="relative text-center justify-center flex">
        <img src={watch} alt="watch" className="float-right ml-10 h-[240px] z-10" />
        <img
          src={speechBubble}
          alt="speech bubble"
          className="absolute z-10 right-1/2 -translate-x-1/4  top-1 -translate-y-1/3 w-[150px] "
        />
        <img
          src={speechBubblePolygon}
          alt="speech bubble polygon"
          className="absolute -translate-x-14 top-1/4 right-1/2 translate-y-1/3"
        />
        <img
          src={text404}
          alt="404"
          className="absolute right-1/2 w-[110px]  -translate-x-1/2 top-1 translate-y-[15%] z-20"
        />

        <img
          src={vector95}
          alt="vector95"
          className="absolute  top-1/2 right-1/2 translate-x-3 -translate-y-1/4 w-[60px] z-20"
        />
        <img
          src={vector96}
          alt="vector96"
          className="absolute right-1/2 translate-x-[98px] top-1/4 -translate-y-1/2 w-[50px] z-20"
        />

        <img
          src={vector97}
          alt="vector97"
          className="absolute top-3/4 translate-x-[60px] right-1/2 translate-y-4  w-[60px] z-20 max-sm:hidden"
        />
        <img
          src={Ellipse}
          alt="Ellipse"
          className="absolute  top-full -translate-y-5 translate-x-5 w-[160px] max-md:hidden"
        />
      </div>
      <div className="text-[32px] leading-[48px] font-bold ">
        <span className="text-myColor">{strings.Page}</span>
        <span> {strings.Not_Found}</span>
      </div>
      <div className="">{strings.Error404_Detial}</div>
      <div className="sm:flex  sm:flex-row gap-3 text-center justify-center flex  flex-col">
        <button
          onClick={() => history.push(`/${strings.getLanguage()}/`)}
          className="rounded bg-myColor text-white px-4 py-2"
        >
          {strings.Go_Home}
        </button>
        <button
          onClick={() => history.goBack()}
          className="rounded bg-gray-500 text-white px-4 py-2"
        >
          {strings.Go_back}
        </button>
      </div>
    </div>
  );
}

export default Error;

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useEffect, useRef, useState } from 'react';
import { strings } from '../../../utils/Localization/LocalizationStrings';

function Calendar({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}: {
  startDate: Date | null;
  endDate: Date | null;
  setStartDate: (date: Date | null) => void;
  setEndDate: (date: Date | null) => void;
}) {
  const [open, setOpen] = useState(false);
  const calendearRef = useRef<HTMLDivElement>(null);

  const openCalender = () => {
    setOpen(true);
  };

  const closeCalender = () => {
    setOpen(false);
  };

  const onChange = ([start, end]: [Date, Date]) => {
    setStartDate(start);
    setEndDate(end);

    if (end != null && start != null) {
      closeCalender();
    }
  };

  useEffect(() => {
    const clickOutside = (e: MouseEvent) => {
      if ((e.target as HTMLElement).classList.contains('react-datepicker__day')) return;
      if (calendearRef.current && calendearRef.current.contains(e.target as Node)) return;
      closeCalender();
    };
    document.addEventListener('click', clickOutside);
    return () => {
      document.removeEventListener('click', clickOutside);
    };
  }, []);

  return (
    <div className="float-right relative" ref={calendearRef}>
      {/* eslint-disable-next-line react/no-unknown-property */}
      <div className="flex items-center">
        <div className="relative " onClick={() => openCalender()} role="presentation">
          <div className="flex absolute inset-y-0 right-0 items-center pr-3 pointer-events-none z-50">
            <svg
              aria-hidden="true"
              className="w-5 h-5 text-myColor"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <input
            placeholder={strings.YearMonthDayUnit}
            value={
              startDate
                ? startDate.toLocaleString('ko-KR', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  })
                : ''
            }
            readOnly
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
        <span className="mx-4 text-gray-500">-</span>
        <div className="relative" onClick={() => openCalender()} role="presentation">
          <div className="flex absolute inset-y-0 right-0 items-center pr-3 pointer-events-none z-50">
            <svg
              aria-hidden="true"
              className="w-5 h-5 text-myColor"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <input
            placeholder={strings.YearMonthDayUnit}
            value={
              endDate
                ? endDate.toLocaleString('ko-KR', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  })
                : ''
            }
            readOnly
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5   dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
      </div>

      <div className="absolute left-1/2 -translate-x-1/2 z-[51]">
        {open ? (
          <DatePicker
            selected={startDate}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            inline
            onChange={onChange}
            renderCustomHeader={({ decreaseMonth, increaseMonth, monthDate }) => (
              <div>
                <span className="font-bold text-base relative left-0">
                  {monthDate.toLocaleString('ko-KR', {
                    year: 'numeric',
                    month: '2-digit',
                  })}
                </span>
                <button
                  aria-label="Previous Month"
                  className=" bg-none relative left-9 border-none "
                  onClick={decreaseMonth}
                >
                  <span className="text-lg font-bold">{'<'}</span>
                </button>
                <button
                  aria-label="Next Month"
                  className="bg-none relative left-14 border-none"
                  onClick={increaseMonth}
                >
                  <span className="text-lg font-bold">{'>'}</span>
                </button>
              </div>
            )}
          />
        ) : null}
      </div>
    </div>
  );
}

export default Calendar;

import { jsonFetch } from './APICall';
import { CURRENCY_ENDPOINT } from './APIEndpoints';

export const getCurrency = async (currency: string, value: number): Promise<number> => {
  const {
    data: { rates },
  } = await jsonFetch(CURRENCY_ENDPOINT);

  if (rates[currency] == null) throw new Error('unsupported currency');

  return rates[currency] * value;
};

export default {
  getCurrency,
};

/* eslint-disable camelcase */
import BigNumber from 'bignumber.js';
import NFTTransactions, { getEventArg } from './NFTTransactions';
import addresses from '../../constants/addresses';

export default class MarketTransactions extends NFTTransactions {
  sellNFT = async ({
    id,
    title,
    category,
    royalty = 0,
    price,
  }: {
    id: number;
    title: string;
    category: number;
    price: number;
    royalty?: number;
  }) => {
    const result = await this.contracts.nftMarketContract.sellItem(
      addresses.nft,
      id,
      new BigNumber(price).times(new BigNumber(10).pow(18)).toNumber().toFixed(0),
      title,
      category,
      1,
      new BigNumber(royalty).times(100).toString(),
      '',
      { from: this.address }
    );

    const { events } = await result.wait();
    const itemId = getEventArg(events, 'Listed', 'itemId') as BigNumber;
    return itemId.toNumber();
  };

  getSale = async (saleId: string): Promise<{ price: string }> =>
    this.contracts.nftMarketContract.items(saleId);

  buyItem = async (saleId: string) => {
    const { price } = await this.getSale(saleId);

    return this.contracts.nftMarketContract.buyItem(saleId, {
      from: this.address,
      value: price,
    });
  };

  cancelSale = async (saleId: number) =>
    this.contracts.nftMarketContract.cancelSelling(saleId, { from: this.address });

  withdrawCredit = async () =>
    this.contracts.nftMarketContract.withdrawCredit({ from: this.address });

  setAdminAddress = async (address: string) =>
    this.contracts.nftMarketContract.setAdmin(address, { from: this.address });

  setFeeAddress = async (address: string) =>
    this.contracts.nftMarketContract.setFeeAddress(address, { from: this.address });
}

import { Link } from 'react-router-dom';
import watchPriceImg from '../assets/img/watchPriceIcon.svg';
import errorImage from '../assets/img/clearButtonIcon.svg';

interface WatchFaceItemProps {
  watchName: string;
  watchOwner: string;
  watchFaceImg: string;
  watchPrice?: number;
  url?: string;
  mobileDirection?: 'row' | 'col';
}

function WatchFaceItem({
  watchName,
  watchOwner,
  watchPrice,
  watchFaceImg,
  url,
  mobileDirection,
}: WatchFaceItemProps) {
  return (
    <li
      className={`list-none p-1 w-1/2 ${
        mobileDirection === 'row' ? 'max-md:min-w-[180px]' : ''
      } max-w-[227px]`}
    >
      <Link to={url || ''} className="block w-full h-full">
        <div className="shadow-lg rounded-[8px] p-2 w-full h-full">
          <img
            src={watchFaceImg}
            className="max-h-[200px] max-w-[200px] w-full mx-auto"
            onError={({ currentTarget }) => {
              // eslint-disable-next-line no-param-reassign
              currentTarget.src = errorImage;
            }}
            alt="watchfaceImage"
          />
          <div className="ml-[4.5px] bg-white rounded-[8px]">
            <h1 className="text-[16px] mt-[10px] font-semibold whitespace-nowrap overflow-hidden text-ellipsis">
              {watchName}
            </h1>
            <p>
              <strong className="text-[12px]  font-normal ">{watchOwner}</strong>
            </p>
            {watchPrice != null && (
              <div>
                <div className="inline-block my-[4.5px] items-center bg-[#E9EAEC] rounded-[8px]">
                  <img
                    src={watchPriceImg}
                    className="h-[14px] inline-block"
                    alt="watchFacePriceImage"
                  />
                  <strong className="text-[14px] text-[#515A63] font-normal leading-[24px] mx-[4px] ">
                    {watchPrice}
                  </strong>
                </div>
              </div>
            )}
          </div>
        </div>
      </Link>
    </li>
  );
}

export default WatchFaceItem;

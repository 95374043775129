import Bannerlist from './AdminBannerList';

interface ITabProps {
  bannerType: number;
  setBannerType: (num: number) => void;
  applyType: string;
  setApplyType: (type: string) => void;
  detailTabList: string[];
}

function AdminDetailTab({
  bannerType,
  setBannerType,
  applyType,
  detailTabList,
  setApplyType,
}: ITabProps) {
  return (
    <>
      <ul className="flex font-bold text-slate-700 text-[12px]">
        {detailTabList.map((item) => (
          <li
            role="presentation"
            onClick={() => setApplyType(item)}
            className={` ${
              applyType === item ? ' text-white bg-myColor' : 'text-black bg-white'
            } inline-block text-center w-full py-2 cursor-pointer`}
            key={item}
          >
            {item}
          </li>
        ))}
      </ul>
      <Bannerlist banner={bannerType} setBanner={setBannerType} />
    </>
  );
}

export default AdminDetailTab;

import { strings } from '../../../utils/Localization/LocalizationStrings';

interface ITabProps {
  banner: number;
  setBanner: (num: number) => void;
}

const bannerlist = [
  {
    title: strings.TopMultiBanner,
  },
  {
    title: strings.MiddleMultiBanner,
  },
  {
    title: strings.BottomMultiBanner,
  },
];

function AdminBannerList({ banner, setBanner }: ITabProps) {
  return (
    <ul className="text-[10px]">
      {bannerlist.map((item, key) => (
        <li
          role="presentation"
          onClick={() => setBanner(key)}
          className={`${
            banner === key ? 'bg-slate-100' : ''
          } px-5 py-2  bg-white border-b-2 cursor-pointer`}
          key={item.title}
        >
          {item.title}
        </li>
      ))}
    </ul>
  );
}

export default AdminBannerList;

import { useEffect, useRef, useState } from 'react';
import SelectArrow from '../assets/img/Rectangle 1310.svg';

interface Props {
  options: { key: string; value: string }[];
  textAlign: string;
  selected: string;
  onOptionChange: (options: string) => void;
  id: string;
}

function AdminReportSelect({ options, textAlign, selected, onOptionChange, id }: Props) {
  const [open, setOpen] = useState(false);
  const selectBoxRef = useRef<HTMLUListElement>(null);

  const handleClickOutSide = (e: MouseEvent) => {
    if (selectBoxRef.current && !selectBoxRef.current.contains(e.target as HTMLElement) && open)
      setOpen(false);
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutSide);
    return () => {
      document.removeEventListener('click', handleClickOutSide);
    };
  });

  return (
    <div className="rounded-lg relative h-9">
      <ul
        role="presentation"
        onClick={(e) => {
          e.stopPropagation();
          setOpen((v) => !v);
        }}
        className={`list-none border-2 bg-white rounded w-full absolute top-0 cursor-pointer ${
          open ? 'border-blue-500 z-50' : ''
        }`}
        ref={selectBoxRef}
      >
        {options
          .filter(({ value }) => open || value === selected)
          .map(({ key, value }) => (
            <li
              key={`${id} ${key}`}
              className={`${textAlign} pl-2 pr-6 py-2 block bg-white rounded`}
            >
              <button
                onClick={(e) => {
                  if (!open) return;
                  e.stopPropagation();
                  onOptionChange(value);
                  setOpen(false);
                }}
              >
                {key}
              </button>
            </li>
          ))}
      </ul>
      <img
        src={SelectArrow}
        alt="셀렉트 박스 화살표"
        className={`${open ? 'rotate-180' : ''} absolute top-1/2 -translate-y-1/2 right-2 z-50`}
      />
    </div>
  );
}

export default AdminReportSelect;

import { useEffect } from 'react';

export default function MessageListener() {
  const handleMessage = (event: any) => {
    const domains = ['apposter.com', 'timeflik.com', 'localhost'];
    if (!domains.some((domain) => event.origin.includes(domain))) {
      return;
    }

    if (event.data === undefined || event.data.data === undefined) return;
    const data = JSON.parse(event.data.data);

    switch (event.data.type) {
      case 'nft-marketplace':
        if (data.key === 'instantToken') {
          localStorage.setItem('X-Instant-Token', data.value);
        }
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return <div />;
}

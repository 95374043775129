/* eslint-disable jsx-a11y/label-has-associated-control */
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import BigNumber from 'bignumber.js';
import { strings } from '../../utils/Localization/LocalizationStrings';
import BaseButton from '../../components/BaseButton';
import SelectBox from '../../components/SelectBox';

import UserinfoContext from '../../context/UserinfoContext';
import { WatchFaceNFT, WatchFaceNFTToken } from '../../types';
import { getStudioWatch, mintWatchNFT, registerWatchNFTSale } from '../../modules/API/WatchAPI';
import { getCurrency } from '../../modules/API/CurrencyAPI';
import { CategoryCheckBoxDefaultInputs } from '../../hooks/useWatchfaceFilter';
import Modal from '../../components/Modal';
import WalletConnectContext from '../../context/WalletConnectContext';

class UserCancelError extends Error {
  constructor() {
    super('User Canceld Transaction');
    this.name = 'UserCancelError';
  }
}

function SaleRegister({
  watchFace,
  toggle,
  fetchWatchFace,
}: {
  watchFace: WatchFaceNFT;
  toggle: (state: boolean, message?: string) => void;
  fetchWatchFace: () => Promise<void>;
}) {
  const [onUnlockableContent, setOnLockableContent] = useState(false);
  const [selectedOption, setSelectOption] = useState('Ethereum');
  const [tokenInfo, setTokenInfo] = useState<WatchFaceNFTToken | null>(null);
  const [dollarRate, setRate] = useState(0);
  const { user } = useContext(UserinfoContext);
  const { connectedWallet, marketTransactions, tokenTransactions } =
    useContext(WalletConnectContext);

  const [royalty, setRoyalty] = useState(0);
  const [price, setPrice] = useState(0);
  const [content, setContent] = useState('');

  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const modalResolver = useRef<() => Promise<void>>(async () => undefined);
  const modalCanceler = useRef<() => Promise<void>>(async () => undefined);

  const openModal = (message: string) => {
    setModalMessage(message);
    setModalOpen(true);
  };

  const awaitModal = async (message: string, modalAction: () => Promise<void>) =>
    new Promise<void>((resolve, reject) => {
      modalResolver.current = async () => {
        try {
          await modalAction();
          resolve();
        } catch (e) {
          reject(e);
          modalResolver.current = async () => undefined;
          setModalOpen(false);
        }
      };
      modalCanceler.current = async () => {
        reject(new UserCancelError());
      };
      openModal(message);
    });

  const history = useHistory();

  const totalPrice = useMemo(
    () => new BigNumber(price).times(new BigNumber(royalty).div(100).plus(1.025)).toNumber() || 0,
    [royalty, price]
  );

  const isSaleButtonConfirm = totalPrice > 0;

  const selectBoxData = {
    options: ['Ethereum'],
    selectedOption,
    textAlign: 'text-right',
    setSelectOption,
  };

  useEffect(() => {
    (async () => {
      if (watchFace.tokenId != null)
        setTokenInfo(await tokenTransactions.getTokenInfo(watchFace.tokenId));
      setRate(await getCurrency('USD', 1));
    })();
  }, [watchFace]);

  useEffect(() => {
    setRoyalty(tokenInfo?.author.royalty ?? 0);
    setContent(tokenInfo?.unlockableContent ?? '');
  }, [tokenInfo]);

  return (
    <section className="sm:w-[665px] my-[20px] w-full">
      <form>
        <h1 className="text-[12px] font-semibold">{strings.Price}</h1>
        <div className="relative flex justify-between mt-[10px]">
          <label htmlFor="blockChain" className="text-[12px] text-[#515A63] z-50 h-[32px]">
            {strings.Blockchain}
          </label>
          <div className="flex flex-1 justify-end">
            <SelectBox
              options={selectBoxData.options}
              selectedOption={selectBoxData.selectedOption}
              setSelectOption={selectBoxData.setSelectOption}
              textAlign={selectBoxData.textAlign}
            />
          </div>
        </div>
        <div className="relative flex sm:justify-between sm:items-center mt-[6px] max-sm:flex-col max-sm:flex-1 max-sm:text-left">
          <label htmlFor="price" className="text-[12px] text-[#515A63]">
            {strings.Price}
          </label>
          <input
            type="number"
            name="price"
            value={price && !Number.isNaN(price) ? price.toString() : 0}
            onChange={({ currentTarget: { value } }) => setPrice(+(value || 0))}
            placeholder="0"
            className="sm:w-[300px] h-[40px] py-[9px] pr-[52px] border border-[#9CA4AC] rounded-[4px] text-[14px] text-right w-full  focus:outline-0 peer"
          />
          <span className="absolute right-[20px] text-[13px] text-[#BBBBBB] max-sm:top-7">
            {strings.ETH}
          </span>
        </div>
        <div className="text-right mt-[14px]">
          <div className="relative flex justify-between sm:items-center max-sm:flex-col max-sm:flex-1 max-sm:text-left  ">
            <label htmlFor="royalty" className="text-[12px] text-[#515A63]">
              {strings.Royalty}
            </label>
            <input
              type="number"
              name="royalty"
              value={royalty && !Number.isNaN(royalty) ? royalty.toString() : 0}
              onChange={({ currentTarget: { value } }) =>
                setRoyalty(Math.min(+Number(value || 0).toFixed(2), 10))
              }
              readOnly={tokenInfo != null}
              placeholder="0"
              className="sm:w-[300px] h-[40px] py-[9px] pr-[41px] border border-[#9199A4] rounded-[4px] text-[14px] text-right w-full  focus:outline-0 peer"
            />
            <span className="absolute right-[20px] text-[13px] text-[#BBBBBB] max-sm:top-7">%</span>
          </div>
          <span className="sm:mr-[33px] text-[12px] text-[#6C757B]  max-sm:float-left">
            {strings.Royalty_Terms}
          </span>
        </div>
      </form>
      <div className="flex justify-between mt-[16px] max-sm:w-full">
        <span className="text-[12px] text-[#515A63]">{strings.Commission}</span>
        <strong className="text-[12px] text-[#2ACFBB] font-medium">
          {strings.Commission_value}
        </strong>
      </div>
      <div className="flex justify-between mt-[20px] px-[10px] py-[6px] bg-[#EFEFEF] flex-wrap">
        <strong className="text-[15px] text-[#515A63] ">{strings.Total_Price}</strong>
        <strong className="text-[15px]">{totalPrice}</strong>
        <p className="w-full text-end text-[11px] mt-2">${(totalPrice * dollarRate).toFixed(3)}</p>
      </div>
      <div className="mt-[20px] border-t border-b py-[20px] border-[#D9D9D9]">
        <div className="flex justify-between">
          <div className="flex flex-col gap-[12px]">
            <span className="text-[12px] font-semibold">{strings.Details}</span>
            <label htmlFor="lockToggle" className="text-[12px] text-[#515A63]">
              {strings.Unlockable_Content}
            </label>
          </div>
          <label
            className="inline-flex relative items-center cursor-pointer mt-auto"
            htmlFor="lockToggle"
          >
            <input
              type="checkbox"
              value=""
              id="lockToggle"
              className="sr-only peer"
              onChange={(e) => setOnLockableContent(e.target.checked)}
            />
            <div className="w-[30px] h-[14px] bg-[#DDDDDD] peer-focus:outline-none rounded-full peer peer-checked:after:-translate-x-full peer-checked:after:left-[calc(100%-2px)] after:content-[''] after:absolute after after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:h-[10px] after:w-[10px] after:transition-all peer-checked:bg-[#2ACFBB]" />
          </label>
        </div>
        {onUnlockableContent &&
          (tokenInfo == null ? (
            <textarea
              className="w-full h-[128px] mt-[10px] p-[8px] border border-[#9CA4AC] rounded-[4px] text-[11px] text-[#515A63] resize-none"
              placeholder="Unlockable Content Keyword"
              name="content"
              value={content}
              onChange={({ currentTarget: { value } }) => setContent(value)}
            />
          ) : (
            <div className="w-full h-[128px] mt-[10px] p-[8px] border border-[#9CA4AC] rounded-[4px] text-[11px] text-[#515A63]">
              {content}
            </div>
          ))}
      </div>
      <div className="max-sm:flex-1 max-sm:flex">
        <BaseButton
          type="button"
          className="disabled:bg-[#9CA4AC] bg-myColor max-sm:py-2 w-full h-full sm:w-[108px] sm:h-[48px] mt-[20px] sm:ml-[83.5%]  text-white font-medium cursor-pointer"
          disabled={!isSaleButtonConfirm}
          onClick={async () => {
            try {
              toggle(true, strings.WorkInProcess);

              if (user == null) return;
              if (connectedWallet == null)
                throw new Error(strings.ConnectWallet_WalletNotConnected);

              let { tokenId } = watchFace;

              if (!(await tokenTransactions.getIsApproved())) {
                await awaitModal(strings.approvalAll, () => tokenTransactions.setApprovalForAll());
              }

              if (tokenId == null) {
                const {
                  model: { builder },
                } = await getStudioWatch(watchFace.appId);

                await awaitModal(strings.mintNFT, async () => {
                  const newTokenId = await tokenTransactions.mintNFT({
                    watchId: watchFace._id,
                    author: {
                      walletAddress: connectedWallet,
                      royalty,
                    },
                    builder,
                    unlockableContent: content || undefined,
                  });

                  await mintWatchNFT(watchFace._id, {
                    owner: connectedWallet,
                    tokenId: newTokenId,
                  });

                  tokenId = newTokenId;
                });
              }

              await awaitModal(strings.proceedSell, async () => {
                const itemId = await marketTransactions.sellNFT({
                  id: tokenId as number,
                  price: new BigNumber(totalPrice).div(1.025).toNumber(),
                  category: Math.max(
                    CategoryCheckBoxDefaultInputs.findIndex(
                      (category) => category.toLowerCase() === watchFace.category.toLowerCase()
                    ),
                    0
                  ),
                  title: watchFace.title,
                  royalty,
                });

                await registerWatchNFTSale(watchFace._id, {
                  tokenId: tokenId as number,
                  saleId: itemId,
                  owner: connectedWallet,
                  price: totalPrice,
                });
              });

              history.push(`/${strings.getLanguage()}/userpage/${user._id}`);
            } catch (error) {
              let message = strings.SellFailed;
              if (error instanceof UserCancelError) message = strings.userCancel;
              openModal(message);
              await fetchWatchFace();
            } finally {
              toggle(false);
            }
          }}
        >
          {strings.To_Sel}
        </BaseButton>
      </div>
      {modalOpen && (
        <Modal
          close={() => {
            modalResolver.current();
            modalResolver.current = async () => undefined;
            modalCanceler.current = async () => undefined;
            setModalOpen(false);
          }}
          outsideClick={() => {
            modalCanceler.current();
            modalResolver.current = async () => undefined;
            modalCanceler.current = async () => undefined;
            setModalOpen(false);
          }}
          header={modalMessage}
        />
      )}
    </section>
  );
}
export default SaleRegister;

import { createContext } from 'react';
import { User } from '../types';

type UserinfoProps = {
  user: User | null;
  setUser: (userinfo: User | null) => void;
};

export default createContext<UserinfoProps>({
  user: null,
  setUser: () => undefined,
});

/* eslint-disable jsx-a11y/label-has-associated-control */
import { useContext, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import BaseButton from './BaseButton';
import BaseModal from './BaseModal';
import icBack from '../assets/img/icn_back.svg';
import { strings } from '../utils/Localization/LocalizationStrings';
import { reportWatchface } from '../modules/API/ReportAPI';
import UserinfoContext from '../context/UserinfoContext';
import { ReportedItem } from '../types';

interface ReportModalProps {
  watchId: string;
  onClose: () => void;
  onConfirm: () => void;
}

const ReportModal = ({ onClose, onConfirm, watchId }: ReportModalProps) => {
  const modalLoad = document.getElementById('modal');
  const [slander, setSladner] = useState(false);
  const [porn, setPorn] = useState(false);
  const [swear, setSwear] = useState(false);
  const [others, setOthers] = useState(false);
  const [details, setDetails] = useState('');
  const [isReportSuccess, setIsReportSuccess] = useState(false);

  const { user } = useContext(UserinfoContext);

  const reason = useMemo(
    () =>
      Object.entries({ slander, porn, swear, others })
        .filter(([, value]) => value)
        .map(([key]) => key) as ReportedItem['reason'],
    [slander, porn, swear, others]
  );

  const isReportButtonConfirm = reason.length > 0 && details !== '';

  const showConfirmModal = () => setIsReportSuccess((prev) => !prev);

  return createPortal(
    <>
      {!isReportSuccess && (
        <>
          <div
            className="fixed top-0 left-0 w-screen h-screen bg-black opacity-20 max-sm:hidden z-[501]"
            onClick={onClose}
            role="presentation"
          />
          <section className="left-0 top-0 max-sm:w-full max-sm:h-full sm:w-[400px] bg-white z-[502] pt-[30px] pb-[43px] px-[20px] fixed sm:top-1/2 sm:left-1/2 sm:translate-x-[-50%] sm:translate-y-[-50%] text-center">
            <img
              src={icBack}
              alt={strings.GoBackIcon}
              className="pt-3 cursor-pointer sm:hidden"
              onClick={onClose}
              role="presentation"
            />

            <h1 className="text-[20px] font-bold">{strings.Report_Content}</h1>
            <div className="flex items-end gap-[3px] mt-[30px] text-right">
              <h2 className="text-[14px] font-medium">{strings.Report_Reson}</h2>
              <span className="inline text-[10px] text-[#FF4141]">
                {strings.ReportUnableCancel}
              </span>
            </div>
            <form className="flex flex-col gap-[16px] py-[20px] mt-[11px] border-t border-b border-[#D9D9D9]">
              <div className="flex justify-between">
                <label htmlFor="slander" className="text-[12px] text-[#515A63]">
                  {strings.Report_Slander}
                </label>
                <input
                  type="checkbox"
                  id="slander"
                  className="w-[18px] h-[18px] filterCheck"
                  onChange={(e) => setSladner(e.target.checked)}
                />
              </div>
              <div className="flex justify-between">
                <label htmlFor="porn" className="text-[12px] text-[#515A63]">
                  {strings.Report_Pornography}
                </label>
                <input
                  type="checkbox"
                  id="porn"
                  className="w-[18px] h-[18px] filterCheck"
                  onChange={(e) => setPorn(e.target.checked)}
                />
              </div>
              <div className="flex justify-between">
                <label htmlFor="swear" className="text-[12px] text-[#515A63]">
                  {strings.Report_Abuse}
                </label>
                <input
                  type="checkbox"
                  id="swear"
                  className="w-[18px] h-[18px] filterCheck"
                  onChange={(e) => setSwear(e.target.checked)}
                />
              </div>
              <div className="flex justify-between">
                <label htmlFor="others" className="text-[12px] text-[#515A63]">
                  {strings.Report_Etc}
                </label>
                <input
                  type="checkbox"
                  id="others"
                  className="w-[18px] h-[18px] accent-myColor"
                  onChange={(e) => setOthers(e.target.checked)}
                />
              </div>
            </form>
            <textarea
              placeholder={strings.Report_DetailReasonTerms}
              className="w-full h-[128px] mt-[20px] rounded-[4px] p-[8px] border border-[#9CA4AC] text-[11px] text-[#515A63] resize-none"
              value={details}
              onChange={(e) => setDetails(e.target.value)}
            />
            <BaseButton
              type="submit"
              className={`w-full h-[40px] mt-[20px] ${
                isReportButtonConfirm ? 'bg-[#2ACFBB]' : 'bg-[#9CA4AC]'
              } text-white text-[16px] font-bold`}
              onClick={async () => {
                if (user == null) return;
                await reportWatchface({
                  watch: watchId,
                  details,
                  reason,
                  reporter: user._id,
                });
                showConfirmModal();
              }}
              disabled={!isReportButtonConfirm}
            >
              {strings.Report_Completed}
            </BaseButton>
          </section>
        </>
      )}
      {isReportSuccess && (
        <BaseModal
          modalDescription={strings.Report_Received}
          modalClassName="w-[296px] sm:w-[400px]  pt-[58px]"
          buttonName={strings.Confirm}
          descriptionClassName="w-[196px]"
          onClick={onConfirm}
        />
      )}
    </>,
    modalLoad as HTMLElement
  );
};

export default ReportModal;

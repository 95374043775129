import { NFTNotification } from '../../types';
import { jsonFetch } from './APICall';
import { NOTIFICATION_ENDPOINT } from './APIEndpoints';

export const registerNotification = async (
  notifications: Omit<NFTNotification, 'read' | 'stored' | '_id'>[]
) =>
  jsonFetch(NOTIFICATION_ENDPOINT, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(notifications),
  });

export const getNotifications = async (walletAddress: string): Promise<NFTNotification[]> =>
  (await jsonFetch(`${NOTIFICATION_ENDPOINT}/${walletAddress}`)).data;

export const readNotifications = async (notificationIds: string[]) =>
  jsonFetch(`${NOTIFICATION_ENDPOINT}/read`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(notificationIds),
  });

export const storeNotification = async (storeBody: { id: string; stored: boolean }[]) =>
  jsonFetch(`${NOTIFICATION_ENDPOINT}/store`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(storeBody),
  });

export default {
  registerNotification,
};

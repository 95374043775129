import { Banner, BannerParams } from '../../types';
import { makeQuery } from '../../utils/stringUtils';
import { jsonFetch } from './APICall';
import { BANNER_ENDPOINT } from './APIEndpoints';

const makeBannerForm = (bannerParams: BannerParams) => {
  const formData = new FormData();
  formData.append('link', bannerParams.link);
  formData.append('position', bannerParams.position);
  formData.append('title', bannerParams.title);
  formData.append('subTitle', bannerParams.subTitle);
  formData.append('buttonText', bannerParams.buttonText);
  formData.append('banner', bannerParams.banner);
  formData.append('draft', bannerParams.draft ? 'true' : 'false');
  if (bannerParams.reservationTime != null) {
    formData.append('reservationTime', bannerParams.reservationTime?.toString());
  }
  return formData;
};

export const getBanner = async ({
  draft = false,
  position,
}: {
  position?: Banner['position'];
  draft?: boolean;
} = {}): Promise<Banner[]> => {
  const response = await jsonFetch(
    `${BANNER_ENDPOINT}/${draft ? 'drafts' : 'current'}?${makeQuery({ position, draft })}`
  );
  return response.data;
};

export const postBanner = async (bannerParams: BannerParams) =>
  jsonFetch(BANNER_ENDPOINT, {
    method: 'POST',
    body: makeBannerForm(bannerParams),
  });

export const updateBanner = async (id: string, bannerParams: BannerParams) =>
  jsonFetch(`${BANNER_ENDPOINT}/${id}`, {
    method: 'PUT',
    body: makeBannerForm(bannerParams),
  });

export const deleteBanner = async (id: string) =>
  jsonFetch(`${BANNER_ENDPOINT}/${id}`, {
    method: 'DELETE',
  });

import { useContext, useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import { strings } from '../utils/Localization/LocalizationStrings';
import { WatchFaceNFTEvent } from '../types';
import { ellipsisBetween, relativeDateString } from '../utils/stringUtils';
import WalletConnectContext from '../context/WalletConnectContext';
import addresses from '../constants/addresses';

interface ItemHistoryProps {
  tokenId: number;
}

const defaultTd = 'text-gray-700 py-3 leading-6';
const tdClass = `${defaultTd} font-normal sm:text-left sm:pr-8`;

const tdClassWithMint = (minted: boolean) =>
  `${minted ? 'text-[#2ACFBB]' : 'text-gray-700'}  leading-6 sm:text-left sm:pr-8 font-bold`;

export default function ItemHistroy({ tokenId }: ItemHistoryProps) {
  const [itemEvents, setItemEvents] = useState<WatchFaceNFTEvent[]>([]);
  const { staticTransactions } = useContext(WalletConnectContext);

  useEffect(() => {
    (async () => {
      const events = await staticTransactions.listTokenEvents(tokenId);
      setItemEvents(events);
    })();
  }, [tokenId]);

  return (
    <div className="overflow-auto">
      <table className="p-0 w-[665px] mt-5 border-t-2 pb-10 mb-6 table-fixed">
        <thead>
          <tr className="w-full border-b-2 font-bold">
            <td className={defaultTd}>{strings.Event}</td>
            <td className={defaultTd}>{strings.Price}</td>
            <td className={defaultTd}>{strings.From}</td>
            <td className={defaultTd}>{strings.To}</td>
            <td className={defaultTd}>{strings.Date}</td>
          </tr>
        </thead>
        <tbody>
          {itemEvents.map(({ event, id, transactionHash, returnValues }) => {
            return (
              <tr key={id}>
                <td className={tdClass}>{event}</td>
                <td className={tdClass}>
                  {returnValues.price
                    ? new BigNumber(returnValues.price)
                        .div(new BigNumber(10).pow(18))
                        .toNumber()
                        .toFixed(5)
                    : '-'}
                </td>
                <td className={tdClassWithMint(event === 'Minted')}>
                  {ellipsisBetween(returnValues.from, 4)}
                </td>
                <td className={tdClassWithMint(event === 'Minted')}>
                  {ellipsisBetween(returnValues.to || '-', 4)}
                </td>
                <td className={tdClass}>
                  <a
                    href={`${addresses.etherScan}/${transactionHash}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {relativeDateString(+returnValues.timestamp * 1000)}
                  </a>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <hr className="border-t-2 hidden sm:block" />
    </div>
  );
}

import { useContext } from 'react';
import WalletConnectContext from '../../context/WalletConnectContext';

export default function AdminWithdraw() {
  const { marketTransactions } = useContext(WalletConnectContext);

  return (
    <section className="ml-5 mt-5">
      <button
        className="bg-teal-400 rounded text-white py-3 sm:px-10 ml-3"
        onClick={marketTransactions.withdrawCredit}
      >
        withdrawCredit
      </button>
    </section>
  );
}

import { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

const DEFAULT_INPUT_CLASS = 'w-full rounded-[5px]';

interface InputProps {
  type: string;
  className: string;
  placeholder?: string;
  name?: string;
  value?: string | number;
  step?: string | number;
  onChange?: (target: any) => void;
  onFocus?: () => void;
  disabled?: boolean;
}

function BaseInput({
  type,
  placeholder,
  className,
  name,
  value,
  step,
  onChange,
  onFocus,
  disabled,
}: PropsWithChildren<InputProps>) {
  return (
    <input
      disabled={disabled}
      type={type}
      placeholder={placeholder}
      className={twMerge(DEFAULT_INPUT_CLASS, className)}
      onChange={onChange}
      onFocus={onFocus}
      name={name}
      value={value}
      step={step}
    />
  );
}

export default BaseInput;

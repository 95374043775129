/* eslint-disable jsx-a11y/label-has-associated-control */
import { PropsWithChildren, useState } from 'react';
import { createPortal } from 'react-dom';
import { twMerge } from 'tailwind-merge';
import DatePicker from 'react-datepicker';
import BaseButton from '../../../components/BaseButton';
import { strings } from '../../../utils/Localization/LocalizationStrings';

const DEFAULT_MODAL_CLASS =
  'fixed top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] z-[10001] flex flex-col justify-between items-center bg-white px-[20px] py-[30px] font-bold text-center w-[400px] space-y-[15px]';

interface ReservationModalProps {
  reservation: Date | null;
  setReservation: (date: Date) => void;
  modalClassName?: string;
  onClick: () => void;
}

function ReservationModal({
  reservation,
  setReservation,
  modalClassName,
  onClick,
}: PropsWithChildren<ReservationModalProps>) {
  const modalLoad = document.getElementById('modal');
  if (modalLoad == null) return null;

  const [selectedDate, setSelectedDate] = useState<Date | null>(reservation);
  const [selectedTime, setSelectedTime] = useState<string>('');

  return createPortal(
    <>
      <div
        className="fixed top-0 left-0 w-full h-screen bg-black opacity-20 z-[10000]"
        onClick={onClick}
        role="presentation"
      />
      <section className={twMerge(DEFAULT_MODAL_CLASS, modalClassName)}>
        <div className="mb-[15px]">
          <p className="text-[20px] leading-[22px] font-bold">{strings.reservationList}</p>
        </div>
        <div className="flex w-full justify-between border-b border-b-[#D9D9D9] py-[5px]">
          <span className="text-[12px] leading-[24px] min-h-[24px] font-normal">
            {reservation?.toLocaleDateString()}
          </span>
          {reservation != null && (
            <BaseButton
              type="button"
              className="w-[50px] bg-[#E9EAEC] text-[#333B42] text-[10px] leading-[22px]"
              onClick={onClick}
            >
              {strings.Cancel}
            </BaseButton>
          )}
        </div>
        <div className="w-full relative">
          <p className="font-normal text-[10px] py-1 text-[#515A63] text-start leading-[24px] min-h-[32px]">
            {selectedDate != null && strings.chooseDate}
          </p>
          <div className="relative flex items-center w-full border-b border-b-[#D9D9D9] py-[5px]">
            <div className="w-full font-normal text-[12px] py-1 text-start text-[#515A63] min-h-[24px]">
              {selectedDate?.toLocaleDateString() || strings.chooseDate}
            </div>
            <DatePicker
              id="datePicker"
              name="datePicker"
              className="w-[20px]"
              wrapperClassName="-z-1 w-0"
              calendarClassName="left-10 top-[-205px] rounded-[20px] overflow-hidden border-none"
              todayButton="Today"
              showPopperArrow={false}
              selected={selectedDate}
              onChange={setSelectedDate}
              renderCustomHeader={({ decreaseMonth, increaseMonth, monthDate }) => (
                <div>
                  <span className="font-bold text-base relative left-0">
                    {monthDate.toLocaleString('ko-KR', {
                      year: 'numeric',
                      month: '2-digit',
                    })}
                  </span>
                  <button
                    aria-label="Previous Month"
                    className=" bg-none relative left-9 border-none "
                    onClick={decreaseMonth}
                  >
                    <span className="text-lg font-bold">{'<'}</span>
                  </button>
                  <button
                    aria-label="Next Month"
                    className="bg-none relative left-14 border-none"
                    onClick={increaseMonth}
                  >
                    <span className="text-lg font-bold">{'>'}</span>
                  </button>
                </div>
              )}
              customInput={
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="absolute right-0 -top-[15px]"
                >
                  <rect width="20" height="20" fill="#2ACFBB" />
                  <rect x="2" y="2" width="16" height="6" fill="white" />
                </svg>
              }
            />
          </div>
        </div>
        <div className="w-full border-b border-b-[#D9D9D9] py-[5px]">
          <div className="w-full font-normal text-[12px] py-1 text-start text-[#515A63] min-h-[32px]">
            {selectedTime !== '' && strings.reservationTime}
          </div>
          <input
            className="w-full font-normal text-[12px] py-1"
            placeholder={strings.reservationTime}
            value={`${selectedTime.slice(0, 2)}${
              selectedTime.length > 2 ? ':' : ''
            }${selectedTime.slice(2)}`}
            onChange={({ currentTarget: { value } }) => {
              const time = value.replaceAll(/[^0-9.]/g, '').slice(0, 4);
              let nextHH = time.slice(0, 2);
              let nextMM = time.slice(2);
              if (nextHH !== '' && +nextHH > 23) nextHH = '23';
              if (nextMM !== '' && +nextMM > 59) nextMM = '59';

              setSelectedTime(nextHH + nextMM);
            }}
          />
        </div>
        <div className="flex space-x-3 !mt-[40px]">
          <BaseButton
            type="button"
            className="w-[126px] h-[40px] py-[8px] bg-[#E9EAEC] font-bold text-[#333B42] rounded-[4px]"
            onClick={onClick}
          >
            {strings.Cancel}
          </BaseButton>
          <BaseButton
            type="button"
            className="w-[126px] h-[40px] py-[8px] bg-[#2ACFBB] font-bold text-white rounded-[4px]"
            onClick={() => {
              if (selectedDate == null || selectedTime === '') {
                alert('날짜와 시간을 선택해주세요.');
                return;
              }
              const hourNumber = +selectedTime.slice(0, 2) * 3600000;
              const minuteNumber = +selectedTime.slice(-2) * 60000;
              setReservation(new Date(+selectedDate + hourNumber + minuteNumber));
              onClick();
            }}
          >
            {strings.Confirm}
          </BaseButton>
        </div>
      </section>
    </>,
    modalLoad
  );
}

export default ReservationModal;

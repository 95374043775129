import { useEffect } from 'react';
import { RouteChildrenProps } from 'react-router-dom';

function AuthPage({ match }: RouteChildrenProps<{ token: string }>) {
  useEffect(() => {
    if (match?.params.token) {
      localStorage.setItem('X-Instant-Token', match.params.token);
      window.location.href = '/';
    }
  }, [match]);

  return <div />;
}

export default AuthPage;

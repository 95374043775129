import { ReportedItem } from '../../types';
import { jsonFetch } from './APICall';
import { REPORT_ENDPOINT } from './APIEndpoints';

export const reportWatchface = async (
  reportDetails: Omit<ReportedItem, 'result' | 'watch' | 'reporter' | 'createdAt' | 'updatedAt'> & {
    watch: string;
    reporter: string;
  }
) =>
  jsonFetch(REPORT_ENDPOINT, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(reportDetails),
  });

export const getReportDetails = async (): Promise<ReportedItem[]> => {
  const response = await jsonFetch(REPORT_ENDPOINT);
  return response.data;
};

export const getReportedWatchface = async (appId: string): Promise<ReportedItem> => {
  const response = await jsonFetch(`${REPORT_ENDPOINT}/${appId}`);
  return response.data;
};

export const transitionReportStatus = async (appId: string, result: ReportedItem['result']) =>
  jsonFetch(`${REPORT_ENDPOINT}/${appId}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      result,
    }),
  });

import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from '../components/Modal';
import { strings } from '../utils/Localization/LocalizationStrings';
import WalletConnectContext from '../context/WalletConnectContext';

function Admin() {
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useHistory();
  const onModalOpenHandler = () => {
    setModalOpen(!modalOpen);
  };
  const { staticTransactions, connectedWallet } = useContext(WalletConnectContext);

  return (
    <>
      {modalOpen && (
        <Modal
          close={onModalOpenHandler}
          header={strings.AdminLoginFailModal_Title}
          outsideClick={onModalOpenHandler}
        />
      )}
      <div className=" flex-col space-y-4  h-screen flex items-center justify-center">
        <h1 className="grid place-content-center text-4xl font-bold px-1 mb-20">
          {strings.TIMEFLIK_NFT_Marketplace}
        </h1>
        <button
          className="py-3 px-14 bg-myColor text-white mt-10 text-base rounded-lg focus:outline-none hover:opacity-90"
          onClick={async () => {
            try {
              if (
                connectedWallet == null ||
                !(await staticTransactions.checkAdmin(connectedWallet))
              )
                throw new Error('not admin');
              navigate.push(`/${strings.getLanguage()}/adminhome`);
            } catch (e) {
              setModalOpen(true);
            }
          }}
        >
          {strings.ConnectWallet}
        </button>
      </div>
    </>
  );
}

export default Admin;

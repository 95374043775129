import { createPortal } from 'react-dom';
import { twMerge } from 'tailwind-merge';
import BaseButton from './BaseButton';

const DEFAULT_MODAL_CLASS =
  'fixed top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] z-[10001] flex flex-col justify-between items-center h-[200px] bg-white p-[20px] font-bold text-center';

const DEFAULT_DESCRIPTION_CLASS = 'text-[20px] font-bold';

interface BaseModlProps {
  modalDescription: string;
  buttonName: string;
  modalClassName: string;
  descriptionClassName: string;
  onClick?: () => void;
}

function BaseModal({
  modalDescription,
  buttonName,
  modalClassName,
  descriptionClassName,
  onClick,
}: BaseModlProps) {
  const modalLoad = document.getElementById('modal');
  if (modalLoad == null) return null;

  return createPortal(
    <>
      <div
        className="fixed top-0 left-0 w-full h-screen bg-black opacity-20 z-[10000]"
        onClick={onClick}
        role="presentation"
      />
      <section className={twMerge(DEFAULT_MODAL_CLASS, modalClassName)}>
        <strong className={twMerge(DEFAULT_DESCRIPTION_CLASS, descriptionClassName)}>
          {modalDescription}
        </strong>
        <BaseButton
          type="button"
          className="h-[40px] py-[8px] bg-[#2ACFBB] font-bold text-white rounded-[5px]"
          onClick={onClick}
        >
          {buttonName}
        </BaseButton>
      </section>
    </>,
    modalLoad
  );
}

export default BaseModal;

/* eslint-disable jsx-a11y/label-has-associated-control */
import { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { strings } from '../utils/Localization/LocalizationStrings';
import BaseInput from '../components/BaseInput';
import BaseButton from '../components/BaseButton';
import ReverifyEmailModal from '../components/ReverifyEmailModal';
import logo from '../assets/img/logo.svg';
import checkIcon from '../assets/img/checkIcon.svg';
import checkedIcon from '../assets/img/checkedIcon.svg';
import visibleIcon from '../assets/img/visibleIcon.svg';
import backIcon from '../assets/img/icn_back.svg';
import { checkCode, sendEmail, signup } from '../modules/API/UserAPI';
import AgreeTermsModal from '../containers/Signup/AgreeTermsModal';
import Modal from '../components/Modal';
import useLoading from '../customHooks/useLoading';

const labelDefaultStyle =
  'absolute translate-y-[-50%] left-[10px] bg-white px-[6px] text-[12px] text-[#9199A4]';

const buttonClass =
  'absolute right-[15px] w-[73px] h-[32px] bg-[#2ACFBB] rounded-[4px] text-[12px] text-white font-bold disabled:bg-[#D6DADE] disabled:text-[#9CA4AC]';

const nickNameBorderStyle =
  'border-[1px] border-[#6C757B] pl-[15px] pt-[16px] pb-[14px] text-[15px] focus:outline-0 peer';

function SignUp() {
  const [inputs, setInputs] = useState({
    nickName: '',
    email: '',
    password: '',
    passwordConfirmation: '',
    verifyStatement: '',
  });
  const { nickName, email, password, passwordConfirmation, verifyStatement } = inputs;
  const [inputNickNameFocus, setInputNickNameFocus] = useState(false);
  const [inputEmailFocus, setInputEmailFocus] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showCheckPassword, setShowCheckPassword] = useState(false);
  const [showEmailReverifyModal, setShowEmailReverifyModal] = useState(false);
  const [confirmCode, setConfirmCode] = useState(false);
  const [showAgreeTermsModal, setShowAgreeTermsModal] = useState(false);
  const [checkTerms, setCheckTerms] = useState({ checkPrivacyTerm: false, checkUserTerm: false });
  const { checkUserTerm, checkPrivacyTerm } = checkTerms;
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const openModal = (message: string) => {
    setModalMessage(message);
    setModalOpen(true);
  };

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });
  };

  const CheckTermsHandler = (e: React.MouseEvent<HTMLInputElement>) => {
    const { name, checked } = e.currentTarget;
    if (name === 'AllCheck') {
      setCheckTerms({ checkPrivacyTerm: checked, checkUserTerm: checked });
    } else {
      setCheckTerms({ ...checkTerms, [name]: checked });
    }
  };

  const navigate = useHistory();
  const isButtonConfirm = useMemo(
    () =>
      nickName !== '' &&
      email !== '' &&
      password !== '' &&
      passwordConfirmation !== '' &&
      checkUserTerm &&
      checkPrivacyTerm &&
      confirmCode &&
      password === passwordConfirmation,
    [nickName, email, password, passwordConfirmation, checkUserTerm, checkPrivacyTerm, confirmCode]
  );

  const checkArray = [
    /[0-9]/.test(password),
    /[a-z]/.test(password),
    /[{}[\]/?.,;:|)*~`!^\-_+<>@#$%&\\=('"]/g.test(password),
  ];
  const [passwordNumber, passwordEng, passwordSpecial] = checkArray;

  const filteredTrueCount = checkArray.filter((bool) => bool).length;

  const [emailSent, setEmailSent] = useState(false);
  const [emailVerifed, setVerified] = useState(false);

  const [Loading, setLoading] = useLoading();

  return (
    <>
      <Loading />
      <section className="md:w-[382px] max-md:pb-10 w-full md:flex md:mx-auto md:h-full md:flex-col md:py-[70px] top-1/2 left-1/2 text-center  max-md:px-4  max-md:absolute max-md:top-0 max-md:left-0 max-md:bg-white">
        <div className="mt-6 py-5 md:hidden w-full text-left">
          <button onClick={() => navigate.goBack()}>
            <img src={backIcon} alt={strings.GoBackIcon} />
          </button>
        </div>
        <h1>
          <img src={logo} alt="TimeFlick logo" className="w-[120px] inline-block" />
        </h1>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            const data = {
              email,
              nickname: nickName,
              password,
              passwordConfirmation,
            };

            const { status, error } = await signup(data);
            if (status) {
              navigate.push(`/${strings.getLanguage()}/signupcelebrate`);
            } else {
              if (error === 'email') return openModal(strings.Signup_CheckDuplicateEmail);
              if (error === 'invalid-Nickname') return openModal(strings.Signup_InvalidNickname);
              return openModal(strings.Signup_Signup);
            }
            return null;
          }}
        >
          <h1 className="mt-[47px] text-[42px]">{strings.Signup_TitleText}</h1>
          <label className="block relative mt-[31px]">
            <BaseInput
              name="nickName"
              value={nickName}
              type="text"
              placeholder={
                inputNickNameFocus === false
                  ? strings.Singup_NickNameTerms
                  : strings.Signup_NickNameFocusTerms
              }
              className={`${nickNameBorderStyle} ${
                nickName.length >= 2 ? 'focus:border-[#16BBA7]' : 'focus:border-[#DA1E28]'
              } ${
                inputNickNameFocus && nickName.length < 2 ? 'border-[#DA1E28]' : 'border-[#9199A4]'
              }`}
              onChange={onChangeHandler}
              onFocus={() => {
                setInputNickNameFocus(true);
              }}
            />
            <span
              className={
                inputNickNameFocus
                  ? `${labelDefaultStyle} animate-showLabel ${
                      nickName.length >= 2
                        ? 'peer-focus:text-[#16BBA7]'
                        : 'peer-focus:text-[#DA1E28] text-[#DA1E28]'
                    }`
                  : `${labelDefaultStyle} invisible `
              }
            >
              {strings.Signup_NickNameTerm}
            </span>
            <div className="flex justify-between">
              <span
                className={`${
                  nickName !== ''
                    ? 'mt-[4px] text-[12px] text-[#9199A4]'
                    : 'mt-[4px] text-[12px] text-[#9199A4] invisible'
                }`}
              >
                {strings.Signup_NickNameTerm2}
              </span>
              <span className="text-[12px] text-[#9199A4]">{`${nickName.length}/20`}</span>
            </div>
          </label>
          <label className="block relative mt-[24px] after:block after:clear-both text-[15px]">
            <span
              className={
                inputEmailFocus === true
                  ? `${labelDefaultStyle} animate-showLabel`
                  : `${labelDefaultStyle} invisible`
              }
            >
              {strings.EmailTerm}
            </span>
            <BaseInput
              name="email"
              value={email}
              type="email"
              placeholder={inputEmailFocus === false ? strings.EmailTerm : ''}
              className="border-[1px] border-[#6c757b] pl-[15px] pt-[16px] pb-[12px] focus:outline-0"
              onChange={onChangeHandler}
              onFocus={() => {
                setInputEmailFocus(true);
              }}
            />
            <span className="float-left mt-[4px] text-[12px] text-[#9199A4]">
              {strings.Signup_EmailTerm2}
            </span>
            <BaseButton
              type="button"
              className={`${buttonClass} top-[10px]`}
              disabled={emailSent || !email}
              onClick={() => setShowEmailReverifyModal(true)}
            >
              {strings.Signup_EmailSendButton}
            </BaseButton>
          </label>
          <div className="relative">
            <BaseInput
              name="verifyStatement"
              value={verifyStatement}
              type="text"
              placeholder={strings.Singup_VerifyStatementTerms}
              className="mt-[22px] border-[1px] border-[#6c757b] pl-[15px] pt-[16px] pb-[12px] text-[15px]"
              onChange={onChangeHandler}
              disabled={emailVerifed}
            />
            <BaseButton
              type="button"
              className={`${buttonClass} top-[32px]`}
              disabled={emailVerifed || !verifyStatement}
              onClick={async () => {
                const checkCodeRs = await checkCode({ email, code: verifyStatement });
                if (checkCodeRs) {
                  openModal(strings.Signup_Verificationcompleted);
                  setVerified(true);
                  setConfirmCode(true);
                } else {
                  openModal(strings.Signup_CheckVerification);
                }
              }}
            >
              {strings.Signup_VerifystatementdButton}
            </BaseButton>
          </div>
          <div className="relative">
            <BaseInput
              name="password"
              value={password}
              type={showPassword ? 'text' : 'password'}
              placeholder={strings.Singup_PasswordTerms}
              className="mt-[43px] border-[1px] border-[#6c757b] pl-[15px] pt-[16px] pb-[12px] text-[15px]"
              onChange={onChangeHandler}
            />
            <button
              type="button"
              onClick={() => {
                setShowPassword((prev) => !prev);
              }}
            >
              <img src={visibleIcon} alt="" className="absolute top-[58px] right-[16px]" />
            </button>
            <div className="flex gap-[11.54px] mt-[7px]">
              <div>
                <img
                  src={passwordEng ? `${checkedIcon}` : `${checkIcon}`}
                  alt="check Icon"
                  className="inline-block mr-[5.27px]"
                />
                <span className="text-[12px] text-[#B7C0C6]">{strings.Signup_PasswordTerm_En}</span>
              </div>
              <div>
                <img
                  src={passwordNumber ? `${checkedIcon}` : `${checkIcon}`}
                  alt="check Icon"
                  className="inline-block mr-[5.27px]"
                />
                <span className="text-[12px] text-[#B7C0C6]">
                  {strings.Signup_PasswordTerm_Num}
                </span>
              </div>
              <div className="mr-auto">
                <img
                  src={passwordSpecial ? `${checkedIcon}` : `${checkIcon}`}
                  alt="check Icon"
                  className="inline-block mr-[5.27px]"
                />
                <span className="text-[12px] text-[#B7C0C6]">
                  {strings.Signup_PasswordTerm_Symbol}
                </span>
              </div>
              <div className="flex items-center">
                <span className="text-[12px] text-[#B7C0C6] mr-[8px]">
                  {strings.Signup_PasswordTerm_safety}
                </span>
                <div
                  className={`inline-block w-[15px] h-[5px] bg-[#D6DADE] ${
                    filteredTrueCount === 1 ? 'bg-[#f08d49]' : ''
                  } ${filteredTrueCount === 2 ? 'bg-[#e7c000]' : ''} ${
                    filteredTrueCount === 3 ? 'bg-[#1a73e8]' : ''
                  } rounded-[3px]`}
                />
                <div
                  className={`inline-block w-[15px] h-[5px] bg-[#D6DADE] ${
                    filteredTrueCount === 2 ? 'bg-[#e7c000]' : ''
                  } ${filteredTrueCount === 3 ? 'bg-[#1a73e8]' : ''} rounded-[3px] ml-[2px]`}
                />
                <div
                  className={`inline-block w-[15px] h-[5px] bg-[#D6DADE] ${
                    filteredTrueCount === 3 ? 'bg-[#1a73e8]' : ''
                  } rounded-[3px] ml-[2px]`}
                />
              </div>
            </div>
          </div>
          <div className="relative">
            <BaseInput
              name="passwordConfirmation"
              value={passwordConfirmation}
              type={showCheckPassword ? 'text' : 'password'}
              placeholder={strings.Singup_PasswordTerms}
              className={`mt-[19px] border-[1px] ${
                password === passwordConfirmation ? 'border-[#6c757b]' : 'border-[#DA1E28]'
              } pl-[15px] pt-[16px] pb-[12px] text-[15px]`}
              onChange={onChangeHandler}
            />
            <button
              type="button"
              onClick={() => {
                setShowCheckPassword((prev) => !prev);
              }}
            >
              <img
                src={visibleIcon}
                alt="visiableIcon"
                className="absolute top-[35px] right-[16px]"
              />
            </button>
            {password !== passwordConfirmation && (
              <span className="float-left mt-[4px] text-[12px] text-[#DA1E28]">
                {strings.Signup_CheckPassword}
              </span>
            )}
          </div>
          <div className="flex justify-between items-center h-[56px] mt-[43px] pl-[12px] pr-[16px] bg-[#F7F7F7] text-[12px] rounded-[4px]">
            <div className="flex items-center">
              <input
                type="checkbox"
                name="checkUserTerm"
                checked={checkUserTerm}
                className="mr-[8px] w-[18px] h-[18px] checked:bg-[#2ACFBB] filterCheck"
                onClick={CheckTermsHandler}
              />
              <span>{strings.AgreeUseTermCheckbox}</span>
            </div>

            <button
              className="text-[#6C757B]"
              onClick={() => setShowAgreeTermsModal(true)}
              type="button"
            >
              {strings.ViewMoreButton}
            </button>
          </div>
          <div className="flex justify-between items-center h-[56px] mt-[16px] pl-[12px] pr-[16px] bg-[#F7F7F7] text-[12px] rounded-[4px] filterCheck">
            <div className="flex items-center">
              <input
                type="checkbox"
                name="checkPrivacyTerm"
                className="mr-[8px] w-[18px] h-[18px] checked:bg-[#2ACFBB]"
                checked={checkPrivacyTerm}
                onClick={CheckTermsHandler}
              />
              <span className="float-left">{strings.AgreePrivacyTermCheckbox}</span>
            </div>
            <button
              className="float-right text-[#6C757B]"
              onClick={() => setShowAgreeTermsModal(true)}
              type="button"
            >
              {strings.ViewMoreButton}
            </button>
          </div>
          <BaseButton
            type="submit"
            className="bg-[#2ACFBB] disabled:bg-[#D6DADE] text-[#9CA4AC] mt-[24px] py-[12px]"
            disabled={!isButtonConfirm}
          >
            {strings.NextButton}
          </BaseButton>
        </form>
      </section>
      {showAgreeTermsModal && (
        <AgreeTermsModal
          showModal={() => setShowAgreeTermsModal(false)}
          checkTerms={checkTerms}
          CheckTermsHandler={CheckTermsHandler}
        />
      )}
      {showEmailReverifyModal && (
        <ReverifyEmailModal
          onClose={() => setShowEmailReverifyModal(false)}
          onConfirm={async () => {
            setShowEmailReverifyModal(false);
            try {
              setLoading(true);
              await sendEmail({
                email,
                lang: 'KO',
                name: email.slice(0, email.indexOf('@')),
                callbackUrl: 'https://nft-dev.timeflik.com/kr/signin',
              });
              setEmailSent(true);
              openModal(strings.emailSent);
            } catch (err) {
              openModal(strings.emailSentFail);
            } finally {
              setLoading(false);
            }
          }}
        />
      )}

      {modalOpen && (
        <Modal
          close={() => setModalOpen(false)}
          outsideClick={() => setModalOpen(false)}
          header={modalMessage}
        />
      )}
    </>
  );
}

export default SignUp;

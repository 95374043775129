import { create } from 'ipfs-http-client';

const projectId = '2KoB59pLHW5KmO1gxf4Ajgza9Ku';
const projectSecret = 'dc9bafac3bbf1587c9603b07439e762f';
const authorization = `Basic ${window.btoa(`${projectId}:${projectSecret}`)}`;

const ipfs = create({
  host: 'ipfs.infura.io',
  port: 5001,
  protocol: 'https',
  headers: {
    authorization,
  },
});

export default ipfs;

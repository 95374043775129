import { Link, useHistory } from 'react-router-dom';
import { useContext, useMemo, useState } from 'react';
import NFT from '../../../assets/img/NFT.svg';
import Rectangle from '../../../assets/img/Rectangle 1158.svg';
import Modal from '../../../components/Modal';
import { strings } from '../../../utils/Localization/LocalizationStrings';
import { ellipsisBetween } from '../../../utils/stringUtils';
import WalletConnectContext from '../../../context/WalletConnectContext';

function Topmenu() {
  const [modalOpen, setModalOpen] = useState(false);

  const { connectedWallet } = useContext(WalletConnectContext);

  const currentAddress = useMemo(
    () => connectedWallet && ellipsisBetween(connectedWallet, 4),
    [connectedWallet]
  );

  const navigate = useHistory();
  const onLogoutHandler = () => {
    navigate.push(`/${strings.getLanguage()}/admin`);
  };
  const onModalHandler = () => {
    setModalOpen(!modalOpen);
  };
  return (
    <nav className="flex justify-between pt-[36px] pb-[34px] shadow bg-white w-full z-50 left-0">
      <div className="flex items-center flex-shrink-0 text-black mr-6 ">
        <span className="mx-[120px]">
          <Link to={`/${strings.getLanguage()}/`}>
            <span className="font-semibold text-2xl tracking-tight"> {strings.TMEFLK}</span>
          </Link>
          <div className="relative left-28 -top-7">
            <img src={NFT} alt="NFT" className="z-50 absolute top-2 left-2" />
            <img src={Rectangle} alt="Rectangle" className=" absolute" />
          </div>
        </span>
        <span className="font-semibold text-xl tracking-tight absolute left-[304px]">
          {strings.Marketplace}
        </span>
      </div>
      <div className="mx-[120px] flex items-center">
        <p className="text-myColor mr-8">{currentAddress}</p>
        <button className="font-bold" onClick={onModalHandler}>
          {strings.Logout}
        </button>
        {modalOpen && (
          <Modal
            close={onLogoutHandler}
            outsideClick={onModalHandler}
            header={strings.LogoutModal_Title}
          />
        )}
      </div>
    </nav>
  );
}

export default Topmenu;

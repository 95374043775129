import { useCallback, useEffect, useState } from 'react';
import { strings } from '../../utils/Localization/LocalizationStrings';
import Modal from '../../components/Modal';
import MultiBanner from '../AdminHomeMultiBanner/MultiBanner';
import { BannerItem, BannerParams } from '../../types';
import { deleteBanner, getBanner, postBanner, updateBanner } from '../../modules/API/BannerAPI';
import configs from '../../constants/configs';
import ReservationModal from './AdminHome/ReservationModal';

const modalButtonText = {
  apply: strings.ApplyContentModal_Title,
};

const validateBanners = (banners: BannerItem[]): banners is (BannerParams & { _id: string })[] =>
  banners.every(
    ({ banner, buttonText, link, subTitle, title }) =>
      banner && buttonText && link && subTitle && title
  );

function AdminHomeMuultiBanner({ edit, reservation }: { edit: boolean; reservation: boolean }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [showReservationModal, setShowReservationModal] = useState(false);
  const [header, setHeader] = useState('');
  const [bannerList, setBannerList] = useState<BannerItem[]>([]);

  const updateBannerValue = <Key extends keyof BannerItem>(
    targetIndex: number,
    key: Key,
    value: BannerItem[Key]
  ) =>
    setBannerList(
      bannerList.map((values, index) =>
        index === targetIndex
          ? {
              ...values,
              [key]: value,
              action: key === 'action' ? (value as BannerItem['action']) : 'update',
            }
          : values
      )
    );

  const onModalOpenHandler = () => {
    setModalOpen(!modalOpen);
  };
  const clickButtonModal = (msg: string) => {
    setHeader(msg);
    onModalOpenHandler();
  };

  const fetchBanner = useCallback(async () => {
    const banners = await getBanner({
      draft: edit,
      position: 'top',
    });
    setBannerList(
      banners.map(({ path, ...rests }) => ({
        ...rests,
        banner: `${configs.REACT_APP_ENDPOINT_NFT}/${path}`,
      }))
    );
  }, [edit]);

  const applyBanner = useCallback(
    async ({
      draft = false,
      reservationTime,
    }: {
      reservationTime?: Date;
      draft?: boolean;
    } = {}) => {
      try {
        const createBanners = bannerList.filter(
          ({ _id, action }) => _id == null && action === 'update'
        );
        const updateBanners = bannerList.filter(
          ({ _id, action }) => _id != null && (reservationTime != null || action === 'update')
        );
        const deleteBanners = bannerList.filter(
          ({ _id, action }) => _id != null && action === 'delete'
        );
        if (!validateBanners(createBanners) || !validateBanners(updateBanners))
          throw new Error('정보를 모두 입력해 주세요.');

        await Promise.all([
          ...createBanners.map((e) =>
            postBanner({ ...e, position: 'top', reservationTime, draft })
          ),
          ...updateBanners.map((e) => updateBanner(e._id, { ...e, reservationTime, draft })),
          ...deleteBanners.map((e) => e._id != null && deleteBanner(e._id)),
        ]);
        clickButtonModal(modalButtonText.apply);
        await fetchBanner();
      } catch (err) {
        let message = '에러가 발생했습니다.';
        if (err instanceof Error) {
          message = err.message;
        }
        clickButtonModal(message);
      }
    },
    [bannerList]
  );

  useEffect(() => {
    fetchBanner();
  }, [edit]);

  return (
    <div>
      {modalOpen && (
        <Modal close={onModalOpenHandler} header={header} outsideClick={onModalOpenHandler} />
      )}

      {reservation && showReservationModal && (
        <ReservationModal
          onClick={() => setShowReservationModal(false)}
          reservation={null}
          setReservation={(date) =>
            applyBanner({
              reservationTime: date,
            })
          }
        />
      )}
      <div className="p-4">
        <p className="pl-2 inline font-medium">{strings.MultiBanner}</p>
        <input
          type="text"
          placeholder={strings.LanguageCodeInput}
          className="text-black placeholder:text-gray-200 border-1 relative left-14 h-8 rounded-xl shadow pl-2"
        />
        <div className="float-right">
          <button
            type="button"
            className="font-bold px-7 py-2 mx-2 rounded-lg bg-gray-400 text-white"
            onClick={() => {
              fetchBanner();
            }}
          >
            {strings.Cancel}
          </button>
          {edit && (
            <button
              className="font-bold px-7 py-2 mx-2 rounded-lg bg-myColor text-white"
              type="submit"
              onClick={() =>
                applyBanner({
                  draft: true,
                })
              }
            >
              저장
            </button>
          )}
          <button
            className="font-bold px-7 py-2 mx-2 rounded-lg bg-myColor text-white"
            type="submit"
            onClick={async () => {
              if (reservation && edit) {
                setShowReservationModal(true);
                return;
              }
              await applyBanner({
                reservationTime: edit ? new Date() : undefined,
              });
            }}
          >
            {reservation && edit ? '예약' : strings.Apply}
          </button>
        </div>
      </div>
      <div className="w-full flex flex-col h-full overflow-y-hidden overflow-x-auto">
        <div className="flex p-3 h-full">
          {bannerList.map(
            (datas, index) =>
              datas.action !== 'delete' && (
                <MultiBanner
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  datas={datas}
                  onRemove={(targetIndex) => updateBannerValue(targetIndex, 'action', 'delete')}
                  index={index}
                  dataUpdater={(key, value) => {
                    updateBannerValue(index, key, value);
                  }}
                  readOnly={!edit}
                />
              )
          )}
          {edit && (
            <div>
              <button
                onClick={() => setBannerList([...bannerList, { action: 'update' }])}
                className="p-4 h-14 shadow-lg rounded-md "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 0 24 24"
                  width="24px"
                  fill="black"
                  className="bg-white"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
                </svg>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AdminHomeMuultiBanner;

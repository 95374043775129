import { useContext, useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import { WatchFaceNFTEvent } from '../types';
import { ellipsisBetween, parseThumbnail, relativeDateString } from '../utils/stringUtils';
import { getWatchNFTInfoFromToken } from '../modules/API/WatchAPI';
import WalletConnectContext from '../context/WalletConnectContext';

export default function UserActivity({ walletAddress }: { walletAddress: string | null }) {
  const [activities, setActivities] = useState<
    (WatchFaceNFTEvent & {
      itemName: string;
      thumbnail: string | null;
      watchfaceId: string | null;
    })[]
  >([]);

  const { staticTransactions } = useContext(WalletConnectContext);

  useEffect(() => {
    if (walletAddress == null) {
      setActivities([]);
      return;
    }
    (async () => {
      const events = await staticTransactions.listAddressEvents(walletAddress);
      const eventsWithTokenInfo = await Promise.all(
        events.map(async (event) => {
          const watchFace =
            (await getWatchNFTInfoFromToken(event.returnValues.tokenId.toString())) ?? {};
          return {
            ...event,
            itemName: watchFace.title ?? 'unknown',
            thumbnail: parseThumbnail(watchFace?.thumbnail),
            watchfaceId: watchFace?._id,
          };
        })
      );
      setActivities(eventsWithTokenInfo);
    })();
  }, [walletAddress]);

  const thClass = 'py-1';
  const tdClass = 'py-2 break-all';
  const thunbnailClass = 'w-6 h-6 inline-block mr-2';
  const tdClassWithMint = (minted: boolean) => `${minted ? 'text-[#2ACFBB]' : ''} font-bold`;

  return (
    <section className="md:w-[calc(100%-250px)] overflow-x-scroll mb-10">
      <table className="w-[956px] text-left mt-8 border-b table-fixed">
        <thead className="text-[#515A63] text-left border-y">
          <tr>
            <th className={thClass}>Item</th>
            <th className={thClass}>Event</th>
            <th className={thClass}>Price</th>
            <th className={thClass}>From</th>
            <th className={thClass}>To</th>
            <th className={thClass}>Date</th>
          </tr>
        </thead>
        <tbody className="text-[#515A63]">
          {activities.map(
            ({ id, event, itemName, thumbnail, returnValues: { price, from, to, timestamp } }) => (
              <tr key={id}>
                <td className={`${tdClass} flex items-center`}>
                  {thumbnail ? (
                    <img
                      className={thunbnailClass}
                      src={thumbnail || ''}
                      alt="watchFace thumbnail"
                    />
                  ) : (
                    <span className={`${thunbnailClass} bg-[#D9D9D9] rounded-[50%]`} />
                  )}
                  <span>{itemName}</span>
                </td>
                <td className={tdClass}>{event}</td>
                <td className={tdClass}>
                  {price
                    ? new BigNumber(price).div(new BigNumber(10).pow(18)).toNumber().toFixed(3)
                    : '-'}
                </td>
                <td className={`${tdClass} ${tdClassWithMint(event === 'Minted')}`}>
                  {ellipsisBetween(from, 4)}
                </td>
                <td className={`${tdClass} ${tdClassWithMint(event === 'Minted')}`}>
                  {ellipsisBetween(to ?? '-', 4)}
                </td>
                <td className={tdClass}>{relativeDateString(timestamp * 1000)}</td>
              </tr>
            )
          )}
        </tbody>
      </table>
    </section>
  );
}

import { useState } from 'react';
import AdminDetailTab from './AdminHome/AdminDetailTab';
import AdminTab from './AdminHome/AdminTab';
import Banner from './AdminHome/Banner';
import { BannerParams } from '../../types';
import AdminHomeMuultiBanner from './AdminHomeMultiBanner';
import { strings } from '../../utils/Localization/LocalizationStrings';

const bannerlist: BannerParams['position'][] = ['top', 'center', 'bottom'];
const bannerTabList = [strings.ListBeingEdited, strings.CurrentRealServerList];
const detailTabList = [strings.ServiceApply, strings.ServiceReservation];

export default function AdminBanner() {
  const [bannerType, setBannerType] = useState(0);
  const [opendTab, setOpendTab] = useState<string>(strings.ListBeingEdited);
  const [applyType, setApplyType] = useState<string>(strings.ServiceApply);

  return (
    <section className="flex w-full h-full">
      <div className="min-w-[240px] flex flex-col h-[600px] overflow-y-hidden my-3 bg-neutral-200">
        <AdminTab opendTab={opendTab} setOpendTab={setOpendTab} bannerTabList={bannerTabList} />
        <AdminDetailTab
          bannerType={bannerType}
          setBannerType={setBannerType}
          detailTabList={detailTabList}
          applyType={applyType}
          setApplyType={setApplyType}
        />
      </div>
      <div className="w-full flex flex-col h-full overflow-y-hidden my-3">
        {bannerType === 0 ? (
          <AdminHomeMuultiBanner
            edit={opendTab === bannerTabList[0]}
            reservation={applyType === detailTabList[1]}
          />
        ) : (
          <Banner
            position={bannerlist[bannerType]}
            edit={opendTab === bannerTabList[0]}
            reservation={applyType === detailTabList[1]}
          />
        )}
      </div>
    </section>
  );
}

import { useState } from 'react';
import { strings } from '../../utils/Localization/LocalizationStrings';
import closeIcon from '../../assets/img/closeIcon.svg';
import backIcon from '../../assets/img/icn_back.svg';
import BaseButton from '../../components/BaseButton';

interface AgreeTermsModalProps {
  showModal: () => void;
  checkTerms: { checkUserTerm: boolean; checkPrivacyTerm: boolean };
  CheckTermsHandler: (e: React.MouseEvent<HTMLInputElement>) => void;
}

function AgreeTermsModal({ showModal, checkTerms, CheckTermsHandler }: AgreeTermsModalProps) {
  const { checkUserTerm, checkPrivacyTerm } = checkTerms;
  const isConfirm = checkPrivacyTerm === true && checkUserTerm === true;
  const [AllCheck, setAllCheck] = useState(false);
  return (
    <>
      <div className="fixed top-0 left-0 z-[10001] h-screen w-full bg-black opacity-20" />
      <div className=" z-[10002] opacity-100 w-full h-full md:w-[375px] md:h-[760px] text-black bg-white pt-[20px] pb-[16px] px-[16px] fixed  top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] text-left overflow-y-auto">
        <button
          type="button"
          onClick={showModal}
          className="w-full mr-[4px] mt-[46px] h-[13px] float-right flex items-center justify-end max-md:hidden"
        >
          <img src={closeIcon} alt={strings.CloseButton} />
        </button>
        <button
          onClick={showModal}
          className="w-full ml-[4px] mt-[46px] h-[13px] float-right flex items-center justify-start md:hidden"
        >
          <img src={backIcon} alt={strings.GoBackIcon} />
        </button>
        <p className="mt-[62px] text-[32px] leading-[51px] font-normal text-center">
          {strings.AgreeTermsModal_TitleText}
        </p>
        <p className="mt-[19px] text-[11px] leading-[16px] font-medium">
          {strings.AgreeTermsModal_TitleDetailText}
        </p>
        <div className="flex justify-between items-center h-[56px] mt-[16px] pl-[12px] pr-[16px] bg-[#F7F7F7] text-[12px] rounded-[4px] filterCheck">
          <div className="flex items-center">
            <input
              type="checkbox"
              className="mr-[8px] w-[18px] h-[18px] checked:bg-[#2ACFBB]"
              name="AllCheck"
              checked={AllCheck}
              onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                CheckTermsHandler(e);
                setAllCheck(!AllCheck);
              }}
            />
            <span className={`${AllCheck ? 'text-[#2ACFBB]' : ''} float-left`}>
              {strings.AgreeTermsModal_AllAgree}
            </span>
          </div>
        </div>
        <div className="flex justify-between items-center h-[56px] mt-[16px] pl-[12px] pr-[16px] bg-[#F7F7F7] text-[12px] rounded-[4px] filterCheck">
          <div className="flex items-center">
            <input
              type="checkbox"
              className="mr-[8px] w-[18px] h-[18px] checked:bg-[#2ACFBB]"
              name="checkUserTerm"
              checked={checkUserTerm}
              onClick={CheckTermsHandler}
            />
            <span className={`${checkUserTerm ? 'text-[#2ACFBB]' : ''} float-left`}>
              {strings.AgreeUseTermCheckbox}
            </span>
          </div>
        </div>
        <p className="w-full h-[138px] p-4 overflow-y-scroll whitespace-pre-wrap">
          {strings.AgreeTermsModal_AgreeUseTerm}
        </p>

        <div className="flex justify-between items-center h-[56px] mt-[16px] pl-[12px] pr-[16px] bg-[#F7F7F7] text-[12px] rounded-[4px] filterCheck">
          <div className="flex items-center">
            <input
              type="checkbox"
              className="mr-[8px] w-[18px] h-[18px] checked:bg-[#2ACFBB]"
              name="checkPrivacyTerm"
              checked={checkPrivacyTerm}
              onClick={CheckTermsHandler}
            />
            <span className={`${checkPrivacyTerm ? 'text-[#2ACFBB]' : ''} float-left`}>
              {strings.AgreePrivacyTermCheckbox}
            </span>
          </div>
        </div>
        <p className="w-full h-[138px]  p-4 overflow-y-scroll whitespace-pre-wrap">
          {strings.AgreeTermsModal_AgreePrivacyTerm}
        </p>
        <BaseButton
          type="button"
          className={`${
            isConfirm ? 'bg-[#2ACFBB]' : 'bg-[#D6DADE] text-[#9CA4AC]'
          } mt-[24px] py-[12px] max-md:px-[173.86px] md:h-[48px] `}
          onClick={showModal}
        >
          {strings.AgreeTermsModal_ButtonText}
        </BaseButton>
      </div>
    </>
  );
}

export default AgreeTermsModal;

import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { RouteChildrenProps, useHistory } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CopyToClipboard } from 'react-copy-to-clipboard';
import incShare from '../assets/img/btn-share.svg';
import icnReport from '../assets/img/btn-report.svg';
import icnEth from '../assets/img/icn_eth.svg';
import Modal from '../components/Modal';
import ReportModal from '../components/ReportModal';
import ItemHistroy from '../components/ItemHistroy';
import { strings } from '../utils/Localization/LocalizationStrings';
import UserinfoContext from '../context/UserinfoContext';
import { closeWatchNFTSale, deleteWatchInfo, getWatchNFTInfo } from '../modules/API/WatchAPI';
import { getWalletOwner } from '../modules/API/WalletAPI';
import { getReportedWatchface } from '../modules/API/ReportAPI';
import ReportedReasons from '../utils/Localization/ReportedReasons';
import { User, WatchFaceNFT, WatchFaceNFTToken } from '../types';
import defaultProfile from '../assets/img/default-profile.png';
import { ellipsisBetween, formatDate, parseThumbnail } from '../utils/stringUtils';
import { getCurrency } from '../modules/API/CurrencyAPI';
import { detectMobileDevice } from '../utils/checkMobile';
import errorImage from '../assets/img/clearButtonIcon.svg';
import useLoading from '../customHooks/useLoading';
import WalletConnectContextProvider from '../context/WalletConnectContext';
import addresses from '../constants/addresses';

class UserCancelError extends Error {
  constructor() {
    super('User Canceld Transaction');
    this.name = 'UserCancelError';
  }
}

function Purchase({ match }: RouteChildrenProps<{ id: string }>) {
  const { user } = useContext(UserinfoContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [header, setHeader] = useState('');
  const [copyItemLink, setCopyItemLink] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [closeE, setCloseE] = useState<() => void>(() => () => undefined);
  const [watchFace, setWatchFace] = useState<WatchFaceNFT | null>(null);
  const [tokenInfo, setTokenInfo] = useState<WatchFaceNFTToken | null>(null);
  const navigate = useHistory();
  const [owner, setOwner] = useState<User | null>(null);
  const [reportDetails, setReportDetails] = useState<string | null>(null);
  const [showUnlockableContent, setShowUnlockableContent] = useState(false);

  const [priceInDollar, setPriceInDollar] = useState(0);

  const [Loading, toggle] = useLoading();

  const { tokenTransactions, marketTransactions, requestSigning, connectedWallet } = useContext(
    WalletConnectContextProvider
  );

  const Modaldata = [
    {
      title: strings.PurchaseProgress,
      url: () => {
        setModalOpen(false);
      },
    },
    {
      title: strings.PurchaseComplete,
      url: () => {
        navigate.push(`/${strings.getLanguage()}/userpage/${user?._id}`);
      },
    },
    {
      title: strings.PurchaseFailed,
      url: () => {
        setModalOpen(false);
      },
    },
  ];

  useEffect(() => {
    (async () => {
      if (match == null) return;
      toggle(true);
      const {
        params: { id },
      } = match;
      const watchFaceInfo = await getWatchNFTInfo(id);
      setWatchFace(watchFaceInfo);
      setPriceInDollar(await getCurrency('USD', watchFaceInfo?.price ?? 0));
      toggle(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (watchFace?.tokenId != null) {
        setTokenInfo(await tokenTransactions.getTokenInfo(watchFace.tokenId));
      }
    })();
  }, [watchFace]);

  useEffect(() => {
    (async () => {
      if (watchFace == null) return;
      if (watchFace?.status === 'hidden') {
        const reportedItem = await getReportedWatchface(watchFace.appId);
        setReportDetails(
          reportedItem?.reason
            ?.map((e: keyof typeof ReportedReasons) => ReportedReasons[e])
            .join(', ') || null
        );
      }
      if (watchFace?.owner != null) {
        const { user: walletOwner } = (await getWalletOwner(watchFace.owner)) ?? {};
        setOwner(walletOwner);
        return;
      }
      setOwner(watchFace.author);
    })();
  }, [watchFace]);

  const isOwner = useMemo(() => {
    if (owner == null) return false;
    return user?._id === owner._id;
  }, [owner, user]);

  const detailList = [
    { detail: 'Contract Addresss', val: ellipsisBetween(addresses.nftMarket, 10) },
    { detail: 'Token Id', val: watchFace?.tokenId || '-' },
    { detail: 'Token Standard', val: 'ERC-721' },
    { detail: 'Blockchain', val: 'Ethereum' },
    { detail: 'Creator', val: watchFace?.author.nickname ?? '-' },
    { detail: 'Creator Earnings', val: `${tokenInfo?.author.royalty || '-'}%` },
  ];

  const onCopyItemLinkHandler = () => {
    setCopyItemLink(!copyItemLink);
  };

  const onModalOpenHandler = () => {
    setModalOpen(!modalOpen);
  };
  const showConfirmDeleteModal = () => {
    setConfirmDelete(!confirmDelete);
  };
  const onConfirmDeleteHandler = async () => {
    try {
      toggle(true, strings.WorkInProcess);
      if (watchFace == null) return;
      if (watchFace.tokenId != null) await tokenTransactions.removeNFT(watchFace.tokenId);
      await deleteWatchInfo(watchFace._id);
      navigate.push(`/${strings.getLanguage()}/userpage/${user?._id}`);
    } finally {
      toggle(false);
    }
  };

  const clickButtonModal = (idx: number) => {
    setHeader(Modaldata[idx].title);
    setCloseE(() => Modaldata[idx].url);
    onModalOpenHandler();
  };

  const [alertOpen, setAlertOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const openModal = (message: string) => {
    setModalMessage(message);
    setAlertOpen(true);
  };

  const modalResolver = useRef<() => Promise<void>>(async () => undefined);
  const modalCanceler = useRef<() => Promise<void>>(async () => undefined);

  const awaitModal = async (message: string, modalAction: () => Promise<void>) =>
    new Promise<void>((resolve, reject) => {
      modalResolver.current = async () => {
        try {
          await modalAction();
          resolve();
        } catch (e) {
          reject(e);
          modalResolver.current = async () => undefined;
          setAlertOpen(false);
        }
      };
      modalCanceler.current = async () => {
        reject(new UserCancelError());
      };
      openModal(message);
    });

  return (
    <div className="flex flex-col md:justify-around align-middle sm:mt-[105px] float-left w-full h-full max-md:px-2  max-md:bg-white max-md:overflow-y-scroll">
      <Loading />
      {modalOpen && <Modal close={closeE} header={header} outsideClick={onModalOpenHandler} />}
      {copyItemLink && (
        <Modal
          close={onCopyItemLinkHandler}
          header={strings.CopyItemLinkModal_Title}
          outsideClick={onCopyItemLinkHandler}
          buttonName={strings.Next}
        />
      )}
      {confirmDelete && (
        <Modal
          close={onConfirmDeleteHandler}
          header={strings.ConfirmDeleteMdal_Title}
          outsideClick={showConfirmDeleteModal}
          buttonName={strings.Next}
        />
      )}
      {watchFace && showReportModal && (
        <ReportModal
          onClose={() => setShowReportModal(false)}
          onConfirm={() => {
            setShowReportModal(false);
            navigate.push(`/${strings.getLanguage()}/explore`);
          }}
          watchId={watchFace._id}
        />
      )}
      <div className="flex sm:flex-row m-0 pb-10 flex-col justify-center align-middle w-full">
        <div className="max-sm:mt-9 relative sm:mr-10">
          {reportDetails != null && (
            <div className="text-xl text-white font-bold absolute top-0 left-0 bg-[rgba(0,0,0,0.6)] h-full w-full flex justify-center items-center">
              {strings.HiddenContent}
            </div>
          )}
          <img
            alt="watchfaceImage"
            src={parseThumbnail(watchFace?.thumbnail) ?? ''}
            onError={({ currentTarget }) => {
              // eslint-disable-next-line no-param-reassign
              currentTarget.src = errorImage;
            }}
            className=" sm:w-[408px] sm:h-[408px] w-2/3 h-full m-auto"
          />
        </div>
        <div className="flex flex-col items-start p-0 gap-3 sm:w-[665px] max-sm:px-5 max-sm:mt-6">
          <p className="text-gray-400 font-light w-full text-[10px]">
            {watchFace?.createdAt && formatDate(watchFace.createdAt)}
          </p>
          <p className="font-semibold leading-[170%] text-[20px] sm:text-[27px]">
            {watchFace?.title}
          </p>
          {watchFace?.price && (
            <div>
              <span className="bg-gray-100 inline-block rounded-lg px-1 py-0 mr-2">
                <img alt="price 아이콘" src={icnEth} className="w-4 h-4 inline-block" />
                <span className="leading-6 text-sm font-normal text-gray-700 tracking-[-0.6px] inline-block">
                  {watchFace?.price}
                </span>
              </span>
              <span className="text-[11px] text-[#515A63]">${priceInDollar.toFixed(2)}</span>
            </div>
          )}

          <button
            className="float-left w-full max-sm:hidden text-left"
            onClick={() => {
              if (owner?._id != null)
                navigate.push(`/${strings.getLanguage()}/userpage/${owner?._id}`);
            }}
          >
            <span className="leading-6 font-normal text-gray-700">{strings.Owned_By}</span>
            <img
              src={parseThumbnail(owner?.thumbnail) ?? defaultProfile}
              onError={({ currentTarget }) => {
                // eslint-disable-next-line no-param-reassign
                currentTarget.src = defaultProfile;
              }}
              alt="Owned by"
              className="inline-block pl-[7px] h-6"
            />
            <span className="leading-6 font-medium text-black pl-[7px]">
              {owner?.nickname ?? 'unknown'}
            </span>
          </button>

          <div className="w-full border-[1px] border-[#F0F0F0] box-border rounded flex-none self-stretch h-32 resize-none p-2">
            {watchFace?.description}
          </div>
          {reportDetails != null && (
            <div>
              <p className="mb-5">
                {strings.HiddenItem}
                <br />
                <span className="text-[#FF5656] text-lg">{`(${strings.ReportReason} : ${reportDetails}) `}</span>
              </p>
              <p>
                {strings.ContactUnhide}
                <br />
                <span className="text-[#2ACFBB]">help@apposter.com</span>
              </p>
            </div>
          )}

          {isOwner && (
            <div className="w-full">
              <button
                className={`${
                  showUnlockableContent ? 'bg-teal-400' : 'bg-[#9CA4AC]'
                } rounded text-white py-3 sm:px-4 max-sm:w-full`}
                onClick={() => setShowUnlockableContent((current) => !current)}
              >
                {showUnlockableContent ? 'Close Unlockable Content' : 'View Unlockable Content'}
              </button>
              {showUnlockableContent && (
                <div className="w-full border-[1px] border-[#F0F0F0] box-border rounded flex-none self-stretch h-32 resize-none p-2">
                  {tokenInfo?.unlockableContent}
                </div>
              )}
            </div>
          )}

          <div className="relative sm:mt-7 w-full">
            <div className="sm:absolute right-0 max-sm:mt-3 text-end">
              <CopyToClipboard text={window.location.href} onCopy={() => onCopyItemLinkHandler()}>
                <button type="button" className="w-13 h-13">
                  <img src={incShare} alt="공유 아이콘" />
                </button>
              </CopyToClipboard>
              {!isOwner && (
                <button
                  type="button"
                  onClick={() => setShowReportModal(true)}
                  className="w-13 h-13"
                >
                  <img src={icnReport} alt="신고 아이콘" />
                </button>
              )}
            </div>

            <div className="max-sm:flex-1 max-sm:flex-col max-sm:flex w-full sm:min-h-[48px]">
              {watchFace?.saleId != null &&
                (isOwner ? (
                  <div className="flex flex-wrap">
                    <button
                      className="bg-[#9CA4AC] rounded text-white py-3 sm:px-4 max-sm:w-full"
                      onClick={showConfirmDeleteModal}
                    >
                      {strings.To_Delete}
                    </button>
                    {window.Appbridge != null && watchFace?.tokenId != null && (
                      <button
                        className="border border-teal-400 text-teal-400 sm:bg-teal-400 sm:text-white rounded py-3 sm:px-10 sm:ml-3 max-sm:mt-3 max-sm:w-full"
                        onClick={() =>
                          window.Appbridge?.openDetailPage(watchFace.tokenId?.toString() as string)
                        }
                      >
                        {detectMobileDevice() ? strings.AdjustToWatch : strings.Show_In_App}
                      </button>
                    )}
                  </div>
                ) : (
                  <button
                    className="bg-teal-400 rounded text-white py-3 sm:px-10 max-sm:w-full"
                    onClick={async () => {
                      try {
                        toggle(true, strings.WorkInProcess);
                        if (connectedWallet == null) {
                          openModal(strings.ConnectWallet_WalletNotConnected);
                          return;
                        }
                        await requestSigning('sign for purchase');

                        await awaitModal(strings.proceedPurchase, async () => {
                          if (watchFace?.saleId == null || connectedWallet == null) return;
                          await marketTransactions.buyItem(watchFace?.saleId.toString());
                          await closeWatchNFTSale(watchFace._id, {
                            action: 'sold',
                            owner: connectedWallet,
                          });
                        });
                        clickButtonModal(1);
                      } catch (e) {
                        clickButtonModal(2);
                      } finally {
                        toggle(false);
                      }
                    }}
                  >
                    {strings.Purchase}
                  </button>
                ))}
            </div>
            <div className="max-sm:w-full">
              <hr className="my-5" />
              <span className="font-bold">{strings.Details}</span>
              <table className="p-[3px] w-full h-[168px] table-fixed">
                <tbody>
                  {detailList.map((item) => (
                    <tr key={item.detail}>
                      <td className="text-gray-700">{item.detail}</td>
                      <td className="text-right break-all">{item.val}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <hr className="my-5" />

              {watchFace?.tokenId && (
                <>
                  <span className="font-bold">{strings.Item_Activity}</span>
                  <ItemHistroy tokenId={watchFace.tokenId} />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {alertOpen && (
        <Modal
          close={() => {
            modalResolver.current();
            modalResolver.current = async () => undefined;
            modalCanceler.current = async () => undefined;
            setAlertOpen(false);
          }}
          outsideClick={() => {
            modalCanceler.current();
            modalResolver.current = async () => undefined;
            modalCanceler.current = async () => undefined;
            setAlertOpen(false);
          }}
          header={modalMessage}
        />
      )}
    </div>
  );
}

export default Purchase;

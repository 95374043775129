import { useCallback, useEffect, useMemo, useState } from 'react';
import Calendar from '../AdminReport/Calendar';
import clearButtonIcon from '../../assets/img/clearButtonIcon.svg';
import { strings } from '../../utils/Localization/LocalizationStrings';
import AdminReportSelect from '../../components/AdminReportSelect';
import NoSearchRS from '../../assets/img/searching 1.svg';
import { ReportedItem } from '../../types';
import { getReportDetails, transitionReportStatus } from '../../modules/API/ReportAPI';
import ReportedReasons from '../../utils/Localization/ReportedReasons';
import { formatDateTime, parseThumbnail } from '../../utils/stringUtils';

const options = [
  { key: strings.AdminReport_Select, value: 'pending' },
  { key: strings.AdminReport_HiddenKeep, value: 'hidden' },
  { key: strings.AdminReport_HiddenClear, value: 'canceled' },
];

const END_DATE_OFFSET = 86399999;

function AdminReport() {
  const [searchKeyword, setSearchKeyword] = useState('');
  const [reportedItems, setReportedItems] = useState<ReportedItem[]>([]);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const fetchReportedItems = useCallback(async () => {
    try {
      setReportedItems(await getReportDetails());
    } catch (err) {
      setReportedItems([]);
    }
  }, []);

  useEffect(() => {
    fetchReportedItems();
  }, []);

  const filteredItem = useMemo(
    () =>
      reportedItems.filter(({ createdAt }) => {
        const date = +new Date(createdAt);
        const start = startDate ? +startDate : 0;
        const end = endDate ? +endDate + END_DATE_OFFSET : Infinity;
        return date >= start && date <= end;
      }),
    [startDate, endDate, reportedItems]
  );

  return (
    <div className="w-full ml-5 mt-5">
      <div className="w-full flex flex-row justify-between">
        <div className="relative">
          <input
            type="search"
            placeholder={strings.InputSearch}
            value={searchKeyword}
            className="ml-4 w-[500px] py-[20px] h-[40px] px-[20px] border border-[#9CA4AC] rounded-[30px] text-[14px] placeholder:text-[#AAAAAA]"
            onChange={({ target }) => {
              setSearchKeyword((target as HTMLInputElement).value);
            }}
          />
          {searchKeyword && (
            <button
              className="absolute top-1/2 translate-y-[-50%] right-[15px] cursor-pointer"
              onClick={() => {
                setSearchKeyword('');
              }}
            >
              <img src={clearButtonIcon} alt={strings.SearchClearButtonIncon} />
            </button>
          )}
        </div>
        <div className="float-right">
          <Calendar
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
        </div>
      </div>

      <div className="overflow-x-auto pb-14">
        <table className="w-full text-sm text-center table-fixed whitespace-pre-wrap">
          <thead>
            <tr className="border-b-2">
              <th className="px-4 py-5">{strings.Thums} </th>
              <th className="px-4 py-5">{strings.Reported}</th>
              <th className="px-4 py-5">{strings.Reporter}</th>
              <th className="px-4 py-5">{strings.ReportDate}</th>
              <th className="px-4 py-5">{strings.Reason}</th>
              <th className="px-4 py-5">{strings.ReportDetailReason}</th>
              <th className="px-4 py-5">{strings.ReportAction}</th>
              <th className="px-4 py-5">{strings.ReportActionDate}</th>
            </tr>
          </thead>
          <tbody>
            {filteredItem.length > 0 ? (
              filteredItem.map(({ watch, reporter, ...item }) => (
                <tr className="border-b-2" key={watch._id}>
                  <td className="text-center align-middle mx-0 ">
                    <img
                      src={parseThumbnail(watch.thumbnail) || ''}
                      onError={({ currentTarget }) => {
                        // eslint-disable-next-line no-param-reassign
                        currentTarget.src = clearButtonIcon;
                      }}
                      alt={String(watch._id)}
                      className="w-[80px] h-[80px] inline-block"
                    />
                  </td>
                  <td className="break-all">{watch.author.email}</td>
                  <td className="break-all">{reporter.email}</td>
                  <td className="break-all">{item.createdAt}</td>
                  <td className="break-all">
                    {item.reason
                      ?.map((e: keyof typeof ReportedReasons) => ReportedReasons[e])
                      .join(', ') || null}
                  </td>
                  <td className="break-all">{item.details}</td>
                  <td className="">
                    <AdminReportSelect
                      options={options}
                      textAlign="text-left"
                      selected={item.result}
                      onOptionChange={async (value) => {
                        await transitionReportStatus(watch._id, value as ReportedItem['result']);
                        await fetchReportedItems();
                      }}
                      id={watch._id}
                    />
                  </td>
                  <td>{formatDateTime(item.updatedAt)}</td>
                </tr>
              ))
            ) : (
              <tr className="h-96">
                <td colSpan={8} rowSpan={8}>
                  <div className="">
                    <div className="w-full mb-2">
                      <img
                        src={NoSearchRS}
                        className="inline-block"
                        alt={strings.AdminReport_NoSearchIcon}
                      />
                    </div>
                    <p className="font-bold text-xl">{strings.ReportedNoSearch}</p>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="w-full text-right">
        <span className="px-2">total 0</span>
        <span className="px-2">0 of 0</span>
        <span className="px-2">prev</span>
        <span className="px-2">next</span>
      </div>
    </div>
  );
}

export default AdminReport;

import { strings } from '../../utils/Localization/LocalizationStrings';

type IModalProps = {
  close: () => void;
  outsideClick: () => void;
  header: string;
  buttonName?: string;
};

function Modal({ close, header, outsideClick, buttonName = strings.Confirm }: IModalProps) {
  return (
    <div
      className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-20 z-[10003]"
      onClick={outsideClick}
      role="presentation"
    >
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-[10002]">
        <section className="w-[296px] sm:w-[400px] min-h-[200px] m-0 rounded bg-white animate-none relative z-30 flex flex-col p-5 gap-5">
          <p className=" font-bold w-full text-center break-all min-h-[100px] flex justify-center items-center">
            <span>{header}</span>
          </p>
          <button
            className="text-white bg-myColor rounded w-[264px] sm:w-[360px] h-[40px]"
            onClick={close}
          >
            {buttonName}
          </button>
        </section>
      </div>
    </div>
  );
}

export default Modal;

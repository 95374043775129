import { ButtonHTMLAttributes, PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

const DEFAULT_BUTTON_CLASS = 'w-full rounded-[5px] font-bold';

function BaseButton({
  type,
  children,
  className,
  onClick,
  disabled,
}: PropsWithChildren<ButtonHTMLAttributes<HTMLButtonElement>>) {
  return (
    <button
      type={type}
      className={twMerge(DEFAULT_BUTTON_CLASS, className)}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}

export default BaseButton;

import filterIcon from '../assets/img/filterIcon.svg';
import cancelButton from '../assets/img/cancelSearch.svg';
import SelectBox from './SelectBox';
import { strings } from '../utils/Localization/LocalizationStrings';
import { sortOptions, sortTexts, sortValues } from '../hooks/useWatchfaceFilter';

interface Props {
  showSideBarHandler: () => void;
  placeholder?: string;
  word: string;
  setWord: (value: string) => void;
  setSelectOption: (value: (typeof sortValues)[number]) => void;
  selectedOption: string;
}

function SearchBar({
  showSideBarHandler,
  placeholder,
  word,
  setWord,
  selectedOption,
  setSelectOption,
}: Props) {
  return (
    <section className="flex md:flex-row md:items-start md:gap-5 md:h-16 flex-wrap justify-end">
      <button
        className="w-16 md:w-[224px] md:h-[60px] h-[40px] md:bg-teal-50 rounded flex items-center justify-between md:px-5 float-right cursor-pointer"
        onClick={showSideBarHandler}
      >
        <span className="w-10 h-6 font-semibold leading-6 text-[13px] md:text-base">Filter</span>
        <img className="w-3 md:w-5 max-md:brightness-0 " src={filterIcon} alt="fillter Gruop" />
      </button>
      <div className="md:h-[60px] h-[40px] w-full md:flex-1 max-md:order-first relative">
        <input
          type="text"
          placeholder={placeholder}
          value={word}
          className=" h-full pl-5 w-full border border-[#9CA4AC] rounded-[30px] text-[14px] placeholder:text-[#AAAAAA]"
          onChange={({ target }) => {
            setWord((target as HTMLInputElement).value);
          }}
        />
        <img
          src={cancelButton}
          alt={strings.SearchClearButtonIncon}
          className={`absolute top-1/2 translate-y-[-50%] right-[15px] cursor-pointer ${
            word ? 'visible' : 'invisible'
          }`}
          onClick={() => {
            setWord('');
          }}
          role="presentation"
        />
      </div>
      <div className="md:w-[260px] md:h-[60px] h-[40px] md:bg-teal-50 rounded md:pt-[15px] md:pl-5 p-1">
        <div className="md:w-[220px] md:h-[30px] w-20 text-right font-semibold relative cursor-pointer text-[13px] md:text-base">
          <SelectBox
            options={sortTexts}
            selectedOption={sortOptions.find(({ value }) => value === selectedOption)?.text ?? ''}
            textAlign="text-right"
            setSelectOption={(selectedText) => {
              const value = sortOptions.find(({ text }) => text === selectedText)?.value;
              if (value != null) setSelectOption(value);
            }}
          />
        </div>
      </div>
    </section>
  );
}

export default SearchBar;

SearchBar.defaultProps = {
  placeholder: 'Search',
};

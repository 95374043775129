import { jsonFetch } from './APICall';
import {
  CKCODE,
  CKEMAIL,
  LOGIN,
  RESETPWD,
  SENDEMAIL,
  USERS,
  TOKEN,
  USER_INFO_ENDPOINT,
} from './APIEndpoints';

const header = {
  'Content-Type': 'application/json',
  'X-User-Agent': 'mrtimemaker-mobile',
};
export const token = async ({ tokenId }: { tokenId: string | null }) => {
  try {
    return await jsonFetch(`${TOKEN}/${tokenId}`, {
      method: 'GET',
      headers: header,
    });
  } catch (err) {
    return null;
  }
};

export const login = async (Data: { email: string; password: string }) => {
  try {
    return await jsonFetch(LOGIN, {
      method: 'POST',
      headers: header,
      body: JSON.stringify(Data),
    });
  } catch (err) {
    return false;
  }
};

export const signup = async (data: {
  email: string;
  nickname: string;
  password: string;
  passwordConfirmation: string;
}) => {
  try {
    const rs = await fetch(USERS, {
      method: 'POST',
      headers: header,
      body: JSON.stringify(data),
    });
    const rsData = await rs.json();
    if (rs.status === 201) {
      return { status: true };
    }
    return { status: false, error: rsData.error };
  } catch (err) {
    return { status: false, error: (err as Error).message };
  }
};

export const checkCode = async (data: { email: string; code: string }) => {
  try {
    const checkCodeResult = await fetch(CKCODE, {
      method: 'POST',
      headers: header,
      body: JSON.stringify(data),
    });
    if (checkCodeResult.status === 200) {
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
};

export const sendEmail = async (data: {
  email: string;
  lang: string;
  name: string;
  callbackUrl: string;
}) => {
  try {
    const sendEmailResult = await fetch(SENDEMAIL, {
      method: 'POST',
      headers: header,
      body: JSON.stringify(data),
    });
    if (sendEmailResult.status === 201) {
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
};

export const checkEmail = async (email: string): Promise<boolean> => {
  try {
    const checkEmailResult = await jsonFetch(`${CKEMAIL}/${email}`, {
      method: 'Get',
      headers: header,
    });
    return checkEmailResult;
  } catch (err) {
    return false;
  }
};

export const resetPassword = async (email: { email: string }) =>
  jsonFetch(RESETPWD, {
    method: 'POST',
    headers: header,
    body: JSON.stringify(email),
  });

export const getUserInfo = async (userId: string) => {
  try {
    const userData = await jsonFetch(`${USER_INFO_ENDPOINT}/${userId}`, {
      method: 'GET',
      headers: header,
    });

    return userData.data;
  } catch (err) {
    return null;
  }
};

import { useHistory } from 'react-router-dom';
import { strings, lang } from '../utils/Localization/LocalizationStrings';
import BaseButton from '../components/BaseButton';
import logo from '../assets/img/logo.svg';
import welcomeImg from '../assets/img/welcomeIcon.svg';

function SignUpCelebrate() {
  const navigate = useHistory();
  return (
    <section className="fixed top-1/2 left-1/2 translate-x-[-50%] translate-y-[-40%] md:translate-y-[-50%] w-[382px] text-center max-md:h-full max-md:absolute">
      <img src={logo} alt="logoimage" className="inline-block" />
      <h1 className="mt-[30px] md:mt-[58px] text-[42px] text-[#1A1A24]">
        {strings.SignUpCelebrate_TitleText}
      </h1>
      <img
        src={welcomeImg}
        alt={strings.SignUpCelebrate_FirecrackerIcon}
        className="inline-block mt-[44px] md:mt-[106px]"
      />
      <p className="mt-[8px] text-[24px]"> {strings.SignUpCelebrate_Greetings}</p>
      <p className="mt-[4px text-[16px] font-bold">{strings.SignUpCelebrate_FindStarting}</p>
      <p className="mt-[42px] text-[15px]">{strings.SignUpCelebrate_ForNftWhatchFace}</p>
      <p className="text-[15px]">{strings.SignUpCelebrate_ConnectWallet}</p>
      <BaseButton
        type="button"
        className="w-full px-[27.95px] py-[13px] mt-[58px] md:mt-[162px] bg-[#E9EAEC]"
        onClick={() => navigate.push(`/${strings.getLanguage()}/connectwallet`)}
      >
        {strings.SignUpCelebrate_WalletConnectButton}
      </BaseButton>
      <BaseButton
        type="button"
        className="w-full px-[27.95px] py-[13px] mt-[16px] bg-[#2ACFBB] text-white max-md:mb-16"
        onClick={() => navigate.push(`/${strings.getLanguage()}/`)}
      >
        {strings.SignUpCelebrate_HomeButton}
      </BaseButton>
    </section>
  );
}

export default SignUpCelebrate;

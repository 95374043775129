export default function FAQ() {
  return (
    <div>
      <iframe
        title="FAQ"
        className="w-full md:h-[calc(100vh-180px)] h-[calc(100vh-98px)]"
        src="https://blog.mrtimemaker.com/nft-timeflik-faq/"
      />
    </div>
  );
}

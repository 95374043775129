import { strings } from '../../../utils/Localization/LocalizationStrings';

function AdminTab({
  opendTab,
  setOpendTab,
  bannerTabList,
}: {
  opendTab: string;
  setOpendTab: (tab: string) => void;
  bannerTabList: string[];
}) {
  return (
    <div className="bg-white border-b-2">
      <ul className="flex space-x-4 font-bold text-[13px] px-3  text-slate-700">
        {bannerTabList.map((tab) => (
          <li
            role="presentation"
            onClick={() => setOpendTab(tab)}
            className={` ${
              opendTab === tab ? 'text-black border-b-4 border-b-myColor ' : 'text-gray-200'
            }  inline-block py-2 w-full text-center bg-white rounded`}
            key={tab}
          >
            {tab}
          </li>
        ))}
      </ul>
      <div className="px-3 py-2 whitespace-pre-wrap bg-neutral-200 text-[10px]">
        {strings.Banner_Precautions}
      </div>
    </div>
  );
}

export default AdminTab;

import { useCallback, useEffect, useMemo, useState } from 'react';
import menuCloseButtonIcon from '../assets/img/Group 2485.svg';
import { strings } from '../utils/Localization/LocalizationStrings';
import { NFTNotification } from '../types';

import alertMobileIcon from '../assets/img/Vector.svg';
import unReadAlertMobileIcon from '../assets/img/Component 1.svg';

import alertIcon from '../assets/img/alertIcon.svg';
import unreadAlertIcon from '../assets/img/Group 2389.svg';

import {
  getNotifications,
  readNotifications,
  storeNotification,
} from '../modules/API/NotificationAPI';
import { getWatchNFTInfoFromToken } from '../modules/API/WatchAPI';
import { relativeDateString } from '../utils/stringUtils';

const notificationTabList = [
  { type: 'unread', class: '', tabText: strings.Inbox },
  { type: 'stored', class: '', tabText: strings.Locker },
] as const;

export default function Notifications({
  address,
  mobile,
}: {
  address?: string | null;
  mobile?: boolean;
}) {
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [selectNotificationTab, setSelectNotificationTab] =
    useState<(typeof notificationTabList)[number]['type']>('unread');
  const [notifications, setNotifications] = useState<
    (NFTNotification & {
      name: string;
    })[]
  >([]);

  const updateNotifications = useCallback(async () => {
    if (address == null) {
      setNotifications([]);
      return;
    }
    const fetchedNotifications = await getNotifications(address);
    setNotifications(
      await Promise.all(
        fetchedNotifications.map(async (notification) => {
          const watchInfo = await getWatchNFTInfoFromToken(notification.tokenId.toString());
          return {
            ...notification,
            name: watchInfo?.title ?? 'unknown',
          };
        })
      )
    );
  }, [address]);

  useEffect(() => {
    updateNotifications();
  }, [address]);

  const filteredNotifications = useMemo(
    () => ({
      unread: notifications.filter(({ read }) => !read),
      stored: notifications.filter(({ stored }) => stored),
    }),
    [notifications]
  );

  const notoficationIcon = useMemo(() => {
    const notificationsLength = filteredNotifications.unread.length;
    if (mobile) return notificationsLength > 0 ? unReadAlertMobileIcon : alertMobileIcon;
    return notificationsLength > 0 ? unreadAlertIcon : alertIcon;
  }, [filteredNotifications.unread.length]);

  useEffect(() => {
    if (mobile) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [mobile]);

  return (
    <div className="relative flex items-center">
      <button onClick={() => setNotificationOpen(!notificationOpen)}>
        <img src={notoficationIcon} alt="알람 아이콘" />
      </button>
      {notificationOpen && (
        <div className="fixed top-0 max-md:left-0 md:absolute md:border-b-2 md:top-16 md:right-0 z-[51] max-md:w-screen max-md:h-screen">
          <nav className="flex flex-col md:w-[300px] md:max-h-[400px] bg-white h-full">
            <button
              onClick={() => setNotificationOpen(false)}
              className="absolute top-8 z-[61] right-5 w-4 h-4 md:hidden "
            >
              <img src={menuCloseButtonIcon} alt={strings.CloseButton} />
            </button>
            <ul className="flex  list-none bg-white rounded z-20 p-[15px]">
              {notificationTabList.map(({ type, tabText }) => (
                <li
                  key={type}
                  value={type}
                  className={` ${
                    selectNotificationTab === type
                      ? 'text-black border-teal-400 border-b'
                      : 'text-gray-300'
                  } md:flex items-center py-2 px-3 font-bold cursor-pointer`}
                >
                  <button
                    onClick={() => {
                      setSelectNotificationTab(type);
                    }}
                  >
                    {tabText}
                  </button>
                </li>
              ))}
            </ul>
            {filteredNotifications[selectNotificationTab].length > 0 ? (
              <>
                <ul className="h-full overflow-auto overscroll-none">
                  {filteredNotifications[selectNotificationTab].map(
                    ({ _id, event, logId, timestamp, stored, name }) => {
                      return (
                        <li className="list-none flex flex-wrap p-2 border-b-[1px]" key={logId}>
                          <div className="w-full">
                            <div className="ml-[12.5px] bg-white rounded-[8px] ">
                              <button
                                className="text-[16px] mt-[10px] font-semibold block w-full text-start text-ellipsis overflow-hidden whitespace-nowrap"
                                onClick={async () => {
                                  await readNotifications([_id]);
                                  await updateNotifications();
                                }}
                              >
                                {`${event} : ${name}`}
                              </button>
                              <div className="w-full flex flex-row mb-2 justify-between">
                                <strong className=" text-[12px]  font-normal text-[#888888]">
                                  {strings.Reminder_Time} ({relativeDateString(timestamp * 1000)})
                                </strong>
                                <button
                                  className="text-[12px] font-normal text-teal-400"
                                  onClick={async () => {
                                    if (address == null) return;
                                    await storeNotification([
                                      {
                                        id: _id,
                                        stored: !stored,
                                      },
                                    ]);
                                    await updateNotifications();
                                  }}
                                >
                                  {stored ? strings.KeepCancel : strings.KeepAlarm}
                                </button>
                              </div>
                            </div>
                          </div>
                        </li>
                      );
                    }
                  )}
                </ul>
                {selectNotificationTab === 'unread' && (
                  <div className="bg-[#EEEEEE] flex flex-row md:absolute w-full md:translate-y-full p-2 md:bottom-0">
                    <button
                      className="border-r-2 font-normal mr-2 text-[15px] leading-6 px-2"
                      onClick={async () => {
                        if (address == null) return;
                        await readNotifications(filteredNotifications.unread.map(({ _id }) => _id));
                        await updateNotifications();
                      }}
                    >
                      {strings.AllRead}
                    </button>
                    <button
                      className="font-normal mr-2 text-[15px] leading-6"
                      onClick={async () => {
                        if (address == null) return;
                        await storeNotification(
                          filteredNotifications.unread.map(({ _id }) => ({ id: _id, stored: true }))
                        );
                        await updateNotifications();
                      }}
                    >
                      {strings.AllKeep}
                    </button>
                  </div>
                )}
              </>
            ) : (
              <div className="flex items-center justify-center py-6">
                <p className="text-gray-300 font-bold">No Message</p>
              </div>
            )}
          </nav>
        </div>
      )}
    </div>
  );
}

import configs from '../../constants/configs';

const TIMEFLIK_TEST = configs.REACT_APP_ENDPOINT_MTM;
const TIMEFLIK_TEST_API = `${TIMEFLIK_TEST}/api`;

export const LOGIN = `${TIMEFLIK_TEST_API}/session`;
export const USERS = `${TIMEFLIK_TEST_API}/users`;
export const CKEMAIL = `${TIMEFLIK_TEST_API}/auth/email`;
export const SENDEMAIL = `${TIMEFLIK_TEST_API}/mail/code`;
export const CKCODE = `${TIMEFLIK_TEST_API}/mail/check`;
export const TOKEN = `${TIMEFLIK_TEST_API}/auth/token`;
export const RESETPWD = `${TIMEFLIK_TEST_API}/users/reset-password`;

export const TIMEFLIK_WATCH = configs.REACT_APP_ENDPOINT_WATCH;
export const WATCH_ENDPOINT = `${TIMEFLIK_WATCH}/api/watches`;
export const LIST_PUBLISH_ENDPOINT = `${TIMEFLIK_WATCH}/api/publish-watch`;

export const NFTBACKEND_ENDPOINT = configs.REACT_APP_ENDPOINT_NFT;
export const USER_INFO_ENDPOINT = `${NFTBACKEND_ENDPOINT}/user`;
export const WATCH_NFT_ENDPOINT = `${NFTBACKEND_ENDPOINT}/watch`;
export const WATCHFACE_NFT_ENDPOINT = `${NFTBACKEND_ENDPOINT}/watchface`;
export const WALLET_ENDPOINT = `${NFTBACKEND_ENDPOINT}/userwallet`;
export const REPORT_ENDPOINT = `${NFTBACKEND_ENDPOINT}/report`;
export const ADMIN_ENDPOINT = `${NFTBACKEND_ENDPOINT}/admin`;
export const BANNER_ENDPOINT = `${ADMIN_ENDPOINT}/banner`;
export const NOTIFICATION_ENDPOINT = `${NFTBACKEND_ENDPOINT}/notification`;

export const CURRENCY_ENDPOINT = 'https://api.coinbase.com/v2/exchange-rates?currency=ETH';

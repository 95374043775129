import Web3 from 'web3';
import type { AbiItem } from 'web3-utils';
import { WatchFaceNFTEvent, WatchFaceNFTEventTypes } from '../../types';
import NFTMarketCollection from '../../abis/NAMarket.json';
import configs from '../../constants/configs';
import addresses from '../../constants/addresses';

export const marketEvents: WatchFaceNFTEventTypes = [
  'Minted',
  'Listed',
  'Canceled',
  'Sale',
  'AdminCanceled',
];

const defaultWeb3 = new Web3(configs.REACT_APP_WEB3_DEFAULT_PROVIDER);
const contract = new defaultWeb3.eth.Contract(
  NFTMarketCollection as unknown as AbiItem,
  addresses.nftMarket
);

const flatSortEvent = (events: Awaited<ReturnType<typeof contract.getPastEvents>>[]) =>
  events
    .flat()
    .sort(
      ({ event, returnValues: { timestamp: time1 } }, { returnValues: { timestamp: time2 } }) => {
        if (time1 !== time2) return +time2 - +time1;
        return event === 'Minted' ? 1 : -1;
      }
    ) as unknown as WatchFaceNFTEvent[];

export default class StaticTransactions {
  contract = contract;

  listTokenEvents = async (tokenId: number) => {
    const events = await Promise.all(
      marketEvents.map((event) =>
        this.contract.getPastEvents(event, {
          filter: {
            tokenId,
          },
          fromBlock: 0,
        })
      )
    );

    return flatSortEvent(events);
  };

  listAddressEvents = async (address: string) => {
    const fromEvents = await Promise.all(
      marketEvents.map((event) =>
        this.contract.getPastEvents(event, {
          filter: {
            from: address,
          },
          fromBlock: 0,
        })
      )
    );

    const toEvents = await this.contract.getPastEvents('Sale', {
      filter: {
        to: address,
      },
      fromBlock: 0,
    });

    return flatSortEvent([...fromEvents, toEvents]);
  };

  checkAdmin = async (address: string): Promise<boolean> =>
    (await this.contract.methods.adminAddress().call())?.toUpperCase() === address?.toUpperCase();

  checkAdminInfo = async () => {
    return {
      owner: await this.contract.methods.owner().call(),
      admin: await this.contract.methods.adminAddress().call(),
      feeAddress: await this.contract.methods.feeAddress().call(),
    };
  };
}

const {
  REACT_APP_ENDPOINT_MTM,
  REACT_APP_ENDPOINT_WATCH,
  REACT_APP_ENDPOINT_NFT,
  REACT_APP_NFT_CONTRACT_ADDRESS,
  REACT_APP_NFT_MARKET_CONTRACT_ADDRESS,
  REACT_APP_WEB3_DEFAULT_PROVIDER,
  REACT_APP_ETHERSCAN,
  REACT_APP_S3_BUCKET,
  REACT_APP_WALLET_CONNECT_PROJECT_ID,
  REACT_APP_WALLET_CONNECT_CHAINS,
} = process.env;

export default {
  REACT_APP_ENDPOINT_MTM,
  REACT_APP_ENDPOINT_WATCH,
  REACT_APP_ENDPOINT_NFT,
  REACT_APP_NFT_CONTRACT_ADDRESS,
  REACT_APP_NFT_MARKET_CONTRACT_ADDRESS,
  REACT_APP_WEB3_DEFAULT_PROVIDER,
  REACT_APP_ETHERSCAN,
  REACT_APP_S3_BUCKET,
  REACT_APP_WALLET_CONNECT_PROJECT_ID,
  REACT_APP_WALLET_CONNECT_CHAINS,
};

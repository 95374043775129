import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { strings } from '../utils/Localization/LocalizationStrings';
import BaseInput from '../components/BaseInput';
import BaseButton from '../components/BaseButton';
import logo from '../assets/img/logo.svg';
import backIcon from '../assets/img/icn_back.svg';
import useLoading from '../customHooks/useLoading';
import resetPWDSucessImg from '../assets/img/img_password.svg';
import { checkEmail, resetPassword } from '../modules/API/UserAPI';
import Modal from '../components/Modal';

const labelDefaultStyle =
  'absolute translate-y-[-50%] left-[10px] bg-white px-[6px] text-[12px] text-[#9199A4]';

const buttonActiveStyle =
  'mt-[45px] w-full h-[48px] bg-[#2ACFBB] rounded-[4px] text-[12px] text-white font-bold';

const buttonInActiveStyle =
  'mt-[45px] w-full h-[48px] bg-[#D6DADE] rounded-[4px] text-[12px] text-[#9CA4AC] font-bold';

function ResetPassword() {
  const [email, setEmail] = useState('');
  const [tab, setTab] = useState<0 | 1>(0);
  const [Loading, toggle] = useLoading();
  const [inputEmailFocus, setInputEmailFocus] = useState(false);

  const navigate = useHistory();

  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const openModal = (message: string) => {
    setModalMessage(message);
    setModalOpen(true);
  };

  return (
    <section className="absolute max-md:pb-16 md:fixed left-1/2 md:translate-x-[-50%] md:w-[466px] text-center w-full max-md:px-4  top-0 max-md:left-0 max-md:bg-white">
      {tab === 0 && (
        <div className="mt-6 py-5 md:hidden w-full text-left">
          <button onClick={() => navigate.goBack()}>
            <img src={backIcon} alt={strings.GoBackIcon} />
          </button>
        </div>
      )}
      <h1 className={`${tab ? 'mt-[94px]' : 'mt-[0px]'} inline-block  md:mt-[89px]`}>
        <img src={logo} alt="TimeFlick logo" />
      </h1>
      <h1 className="text-[42px] leading-[63px] mt-12 ">
        {strings.PasswordRreset_PasswordRestText}
      </h1>
      {tab ? (
        <div className="flex flex-col justify-center mx-auto gap-1 mt-[80px]">
          <img
            src={resetPWDSucessImg}
            alt="resetPasswordSucessImg"
            className="w-[146px] mx-auto mb-[13px]"
          />
          <p>{strings.PasswordRreset_CheckEmail}</p>
          <p>{strings.PasswordRreset_SendResetPwdLink}</p>
          <button
            className="mt-24 bg-[#2ACFBB] rounded-[4px] text-[12px] text-white font-bold h-[48px]"
            onClick={() => navigate.push(`/${strings.getLanguage()}/signin`)}
          >
            {strings.SigninButton}
          </button>
        </div>
      ) : (
        <>
          <label
            htmlFor="email"
            className="block relative mt-[180px] after:block after:clear-both text-[15px]"
          >
            <span
              className={
                inputEmailFocus === true
                  ? `${labelDefaultStyle} animate-showLabel`
                  : `${labelDefaultStyle} invisible`
              }
            >
              {strings.EmailTerm}
            </span>

            <BaseInput
              name="email"
              value={email}
              type="email"
              placeholder={inputEmailFocus === false ? strings.EmailTerm : ''}
              className="border-[1px] border-[#6c757b] pl-[15px] pt-[16px] pb-[12px] focus:outline-0"
              onChange={({ target }) => setEmail((target as HTMLInputElement).value)}
              onFocus={() => {
                setInputEmailFocus(true);
              }}
            />
            <span className="float-left mt-[4px] text-[12px] text-[#9199A4]">
              {strings.PasswordRreset_InputEmailTerms}
            </span>
          </label>

          <BaseButton
            type="button"
            className={
              email !== '' ? `${buttonActiveStyle} top-[10px]` : `${buttonInActiveStyle} top-[10px]`
            }
            onClick={async () => {
              if (email === '') {
                openModal(strings.Enter_Email);
                return;
              }
              toggle(true);
              const rs = await checkEmail(email);
              if (rs) {
                resetPassword({ email });
                setTab(1);
              } else {
                openModal(strings.Check_Email);
              }
              toggle(false);
            }}
          >
            {strings.PasswordRreset_PasswordRestText}
          </BaseButton>
        </>
      )}
      {modalOpen && (
        <Modal
          close={() => setModalOpen(false)}
          outsideClick={() => setModalOpen(false)}
          header={modalMessage}
        />
      )}
      <Loading />
    </section>
  );
}

export default ResetPassword;

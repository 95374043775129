import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RouteChildrenProps, useHistory } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import CopyToClipboard from 'react-copy-to-clipboard';
import { strings } from '../utils/Localization/LocalizationStrings';
import ReportModal from '../components/ReportModal';
import incShare from '../assets/img/btn-share.svg';
import icnReport from '../assets/img/btn-report.svg';
import Modal from '../components/Modal';
import SaleRegister from '../containers/SaleRegister';
import { closeWatchNFTSale, deleteWatchInfo, getWatchNFTInfo } from '../modules/API/WatchAPI';
import UserinfoContext from '../context/UserinfoContext';
import { getWalletOwner } from '../modules/API/WalletAPI';
import { getReportedWatchface } from '../modules/API/ReportAPI';
import ReportedReasons from '../utils/Localization/ReportedReasons';
import { User, WatchFaceNFT } from '../types';
import defaultProfile from '../assets/img/default-profile.png';
import { formatDate, parseThumbnail } from '../utils/stringUtils';

import errorImage from '../assets/img/clearButtonIcon.svg';
import useLoading from '../customHooks/useLoading';
import WalletConnectContext from '../context/WalletConnectContext';

function ItemDetails({ match }: RouteChildrenProps<{ id: string }>) {
  const { user } = useContext(UserinfoContext);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [saleRegister, setSaleRegister] = useState(false);
  const [copyItemLink, setCopyItemLink] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [watchFace, setWatchFace] = useState<WatchFaceNFT | null>(null);
  const navigate = useHistory();
  const [owner, setOwner] = useState<User | null>(null);
  const [reportDetails, setReportDetails] = useState<string | null>(null);
  const [Loading, toggle] = useLoading();

  const { tokenTransactions, marketTransactions, requestSigning, connectedWallet } =
    useContext(WalletConnectContext);

  const fetchWatchFace = useCallback(async () => {
    if (match == null) return;
    const {
      params: { id },
    } = match;

    setWatchFace(await getWatchNFTInfo(id));
  }, [match]);

  useEffect(() => {
    fetchWatchFace();
  }, []);

  useEffect(() => {
    (async () => {
      if (watchFace == null) return;
      if (watchFace?.status === 'hidden') {
        const reportedItem = await getReportedWatchface(watchFace._id);
        setReportDetails(
          reportedItem?.reason
            ?.map((e: keyof typeof ReportedReasons) => ReportedReasons[e])
            .join(', ') || null
        );
      }
      if (watchFace?.owner != null) {
        const walletOwner = await getWalletOwner(watchFace.owner);
        if (walletOwner != null) setOwner(walletOwner.user);
        return;
      }
      setOwner(watchFace.author);
    })();
  }, [watchFace]);

  const showConfirmDeleteModal = () => {
    setConfirmDelete(!confirmDelete);
  };

  const onConfirmDeleteHandler = async () => {
    try {
      toggle(true, strings.WorkInProcess);
      if (watchFace == null) return;
      if (watchFace.tokenId != null) await tokenTransactions.removeNFT(watchFace.tokenId);
      await deleteWatchInfo(watchFace._id);
      navigate.push(`/${strings.getLanguage()}/userpage/${owner?._id}`);
    } finally {
      toggle(false);
    }
  };

  const isOwner = useMemo(() => {
    if (owner == null) return false;
    return user?._id === owner._id;
  }, [owner, user]);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const openModal = (message: string) => {
    setModalMessage(message);
    setModalOpen(true);
  };

  return (
    <div className="flex flex-col pb-10 md:justify-around align-middle sm:mt-[105px] float-left w-full h-full  max-md:px-2  max-md:bg-white max-md:overflow-y-scroll">
      <Loading />
      {watchFace && showReportModal && (
        <ReportModal
          onClose={() => setShowReportModal(false)}
          onConfirm={() => {
            setShowReportModal(false);
            navigate.push(`/${strings.getLanguage()}/`);
          }}
          watchId={watchFace._id}
        />
      )}
      {copyItemLink && (
        <Modal
          close={() => setCopyItemLink(false)}
          header={strings.CopyItemLinkModal_Title}
          outsideClick={() => setCopyItemLink(false)}
          buttonName={strings.Next}
        />
      )}
      {confirmDelete && (
        <Modal
          close={onConfirmDeleteHandler}
          header={strings.ConfirmDeleteMdal_Title}
          outsideClick={showConfirmDeleteModal}
          buttonName={strings.Next}
        />
      )}
      <div className="flex sm:flex-row m-0 flex-col justify-center align-middle w-full ">
        <div className="max-sm:mt-9 sm:mr-10 relative">
          {reportDetails != null && (
            <div className="text-xl text-white font-bold absolute top-0 left-0 bg-[rgba(0,0,0,0.6)] h-full w-full flex justify-center items-center">
              {strings.HiddenContent}
            </div>
          )}
          <img
            alt="watchface Iamge"
            src={parseThumbnail(`${watchFace?.thumbnail}`) ?? ''}
            onError={({ currentTarget }) => {
              // eslint-disable-next-line no-param-reassign
              currentTarget.src = errorImage;
            }}
            className="sm:w-[408px] sm:h-[408px] w-2/3 h-full m-auto"
          />
        </div>
        <div className="flex flex-col items-start p-0 gap-3 sm:w-[665px] max-sm:px-5 max-sm:mt-6">
          <p className="text-gray-400 font-light w-full text-[10px]">
            {watchFace?.createdAt && formatDate(watchFace.createdAt)}
          </p>
          <p className="font-semibold leading-[170%] text-[20px] sm:text-[27px]">
            {watchFace?.title}
          </p>
          <button
            type="button"
            className="float-left w-full max-sm:hidden text-left"
            onClick={() => navigate.push(`/${strings.getLanguage()}/userpage/${owner?._id}`)}
          >
            <span className="leading-6 font-normal text-gray-700">{strings.Owned_By}</span>
            <img
              src={parseThumbnail(owner?.thumbnail) ?? defaultProfile}
              onError={({ currentTarget }) => {
                // eslint-disable-next-line no-param-reassign
                currentTarget.src = defaultProfile;
              }}
              alt="Owned by"
              className="inline-block ml-[7px] w-6 h-6 rounded-3xl"
            />
            <span className="leading-6 font-medium text-black pl-[7px]">{owner?.nickname}</span>
          </button>
          <div className="float-left w-full sm:hidden">
            <span className="leading-6 font-normal text-gray-700">Category</span>
            <span className="leading-6 font-medium text-black pl-[7px]">Category01</span>
          </div>
          <div className="w-full border-[1px] border-[#F0F0F0] box-border rounded flex-none self-stretch h-32 resize-none p-2">
            {watchFace?.description}
          </div>
          {reportDetails != null && (
            <div>
              <p className="mb-5">
                {strings.HiddenItem}
                <br />
                <span className="text-[#FF5656] text-lg">{`(${strings.ReportReason} : ${reportDetails}) `}</span>
              </p>
              <p>
                {strings.ContactUnhide}
                <br />
                <span className="text-[#2ACFBB]">help@apposter.com</span>
              </p>
            </div>
          )}
          {isOwner && saleRegister && watchFace != null && (
            <SaleRegister watchFace={watchFace} toggle={toggle} fetchWatchFace={fetchWatchFace} />
          )}
          {isOwner &&
            !saleRegister &&
            watchFace?.status !== 'sale' &&
            watchFace?.status !== 'hidden' && (
              <div className="w-full sm:mt-7">
                <div className="max-sm:w-full max-sm:mt-7 float-right ">
                  <CopyToClipboard text={window.location.href} onCopy={() => setCopyItemLink(true)}>
                    <button type="button" className="float-right w-13 h-13">
                      <img src={incShare} alt="공유 아이콘" />
                    </button>
                  </CopyToClipboard>
                </div>
                <div className="max-sm:flex-1 max-sm:flex-col max-sm:flex w-full gap-5 mb-5">
                  <button
                    className="bg-[#9CA4AC] rounded text-white sm:ml-5 px-4 py-3 "
                    onClick={showConfirmDeleteModal}
                  >
                    {strings.To_Delete}
                  </button>
                  <button
                    onClick={async () => {
                      if (connectedWallet == null) {
                        openModal(strings.ConnectWallet_WalletNotConnected);
                        return;
                      }
                      await requestSigning('sign for sale');
                      setSaleRegister(true);
                    }}
                    className="bg-teal-400 rounded text-white sm:ml-5 px-4 py-3"
                  >
                    {strings.To_Sel}
                  </button>
                </div>
              </div>
            )}
          {isOwner && watchFace?.status === 'sale' && (
            <div className="w-full sm:mt-7">
              <div className="max-sm:w-full max-sm:mt-7 float-right ">
                <CopyToClipboard text={window.location.href} onCopy={() => setCopyItemLink(true)}>
                  <button type="button" className="float-right w-13 h-13">
                    <img src={incShare} alt="공유 아이콘" />
                  </button>
                </CopyToClipboard>
              </div>
              <div className="max-sm:flex-1 max-sm:flex-col max-sm:flex w-full gap-5 mb-5">
                <button
                  className="bg-[#9CA4AC] rounded text-white sm:ml-5 px-4 py-3 "
                  onClick={async () => {
                    try {
                      toggle(true);
                      if (watchFace.saleId == null) return;
                      await marketTransactions.cancelSale(watchFace.saleId);
                      await closeWatchNFTSale(watchFace._id, { action: 'canceled' });
                      navigate.push(`/${strings.getLanguage()}/userpage/${owner?._id}`);
                    } finally {
                      toggle(false);
                    }
                  }}
                >
                  {strings.To_Cancel}
                </button>
              </div>
            </div>
          )}
          {!isOwner && watchFace?.status !== 'hidden' && (
            <div className="w-full sm:mt-7">
              <div className="max-sm:w-full max-sm:mt-7 flex">
                <CopyToClipboard text={window.location.href} onCopy={() => setCopyItemLink(true)}>
                  <button type="button" className="w-13 h-13 ml-auto">
                    <img src={incShare} alt="Share Icon" />
                  </button>
                </CopyToClipboard>
                <button
                  type="button"
                  onClick={() => setShowReportModal(true)}
                  className="w-13 h-13"
                >
                  <img src={icnReport} alt="Report Icon" />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      {modalOpen && (
        <Modal
          close={() => setModalOpen(false)}
          outsideClick={() => setModalOpen(false)}
          header={modalMessage}
        />
      )}
    </div>
  );
}

export default ItemDetails;

import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import TopMenu from '../containers/Admin/AdminTopMenu';
import LeftMenu from '../containers/Admin/AdminLeftMenu';
import AdminBanner from '../containers/Admin/AdminBanner';
import AdminReport from '../containers/Admin/AdminReport';
import AdminWithdraw from '../containers/Admin/AdminWithdraw';
import WalletConnectContext from '../context/WalletConnectContext';

const menuList = {
  Home: AdminBanner,
  Report: AdminReport,
  withdrawCredit: AdminWithdraw,
} as const;
export type MenuList = keyof typeof menuList;

function AdminHome() {
  const [selectedMenu, setSelectedMenu] = useState<MenuList>('Home');
  const navigate = useHistory();

  const { staticTransactions, connectedWallet } = useContext(WalletConnectContext);

  const Component = menuList[selectedMenu];

  useEffect(() => {
    (async () => {
      if (connectedWallet == null || !(await staticTransactions.checkAdmin(connectedWallet)))
        navigate.push('/');
    })();
  }, [connectedWallet]);

  return (
    <div className="w-full absolute left-0">
      <TopMenu />
      <div className="flex px-6 w-full flex-wrap">
        <div className="w-[224px]">
          <LeftMenu
            selectedMenu={selectedMenu}
            menus={Object.keys(menuList) as MenuList[]}
            setSelectedMenu={setSelectedMenu}
          />
        </div>
        <div className="w-[calc(100%-224px)]">
          <Component />
        </div>
      </div>
    </div>
  );
}

export default AdminHome;

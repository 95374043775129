import { useEffect, useRef, useState } from 'react';
import src3 from '../../assets/img/Rectangle 1310.svg';

interface Props {
  values: { key: string; length?: number }[];
  selectedMenu: string;
  setSelectedMenu: (menu: string) => void;
}

function TextTap({ values, selectedMenu, setSelectedMenu }: Props) {
  const [open, setOpen] = useState(false);
  const selectBoxRef = useRef<HTMLUListElement>(null);

  const handleClickOutSide = (e: MouseEvent) => {
    if (selectBoxRef.current && !selectBoxRef.current.contains(e.target as Node) && open) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutSide);
    return () => {
      document.removeEventListener('mousedown', handleClickOutSide);
    };
  });
  return (
    <div className="max-md:relative md:border-b-2  z-[51]">
      <nav className="md:flex left">
        <img
          src={src3}
          alt="셀렉트 박스 화살표"
          className={`${open && 'rotate-180'} positon absolute top-5 left-32 z-50 md:hidden`}
        />
        <ul
          className={`${open ? '' : ''} md:flex list-none bg-white rounded  z-20`}
          ref={selectBoxRef}
        >
          {values.map(({ key, length }) => (
            <li
              key={key}
              className={` ${
                selectedMenu === key ? 'text-black border-teal-400 border-b-4' : 'text-gray-300'
              } ${
                selectedMenu === key || open ? ' ' : 'max-md:hidden '
              } md:flex items-center w-32 max-sm:w-32 ml-2  py-2  px-3 font-bold cursor-pointer bg-white sm:text-center text-left`}
              onClick={() => {
                setSelectedMenu(key);
                setOpen(!open);
              }}
              role="presentation"
            >
              {`${key} ${length ?? ''}`}
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
}

export default TextTap;

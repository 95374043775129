import { Link, useHistory } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { strings, lang } from '../utils/Localization/LocalizationStrings';
import BaseInput from '../components/BaseInput';
import BaseButton from '../components/BaseButton';
import logo from '../assets/img/logo.svg';
import facebookLogo from '../assets/img/facebookLogoIcon.svg';
import kakaoLogo from '../assets/img/kakaoLogoIcon.svg';
import googleLogo from '../assets/img/googleLogoIcon.svg';
import appleLogo from '../assets/img/appleLogoIcon.svg';
import lineLogo from '../assets/img/lineLogoIcon.svg';
import backIcon from '../assets/img/icn_back.svg';
import UserinfoContext from '../context/UserinfoContext';
import useLoading from '../customHooks/useLoading';
import SocialLogin from '../components/SocialLogin/SocialLogin';
import { login } from '../modules/API/UserAPI';
import Modal from '../components/Modal';

function SignIn() {
  const { user, setUser } = useContext(UserinfoContext);
  const [Loading, toggle] = useLoading();
  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUSerPassword] = useState('');

  const navigate = useHistory();
  useEffect(() => {
    if (user != null) {
      navigate.goBack();
    }
  }, [user]);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const openModal = (message: string) => {
    setModalMessage(message);
    setModalOpen(true);
  };

  useEffect(() => {
    document.body.style.overscrollBehavior = 'contain';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <section>
      <div className="absolute max-md:pb-16 md:fixed top-1/2 left-1/2 md:translate-x-[-50%] md:translate-y-[-50%] md:w-[466px] text-center max-md:px-4  max-md:absolute max-md:top-0 max-md:left-0 max-md:bg-white">
        <div className="mt-6 py-5 md:hidden w-full text-left">
          <button onClick={() => navigate.push('/')}>
            <img src={backIcon} alt={strings.GoBackIcon} />
          </button>
        </div>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            if (userEmail === '') {
              openModal(strings.Enter_Email);
              return;
            }
            if (userPassword === '') {
              openModal(strings.Enter_Password);
              return;
            }
            toggle(true);
            const data = { email: userEmail, password: userPassword };
            const rs = await login(data);
            if (!rs) {
              toggle(false);
              openModal(strings.Signin_Signin);
            } else {
              localStorage.setItem('X-Instant-Token', rs.instantToken);
              setUser(rs);
              navigate.push(`/${strings.getLanguage()}/`);
              toggle(false);
            }
          }}
        >
          <h1 className="inline-block">
            <img src={logo} alt="TimeFlick logo" />
          </h1>

          <BaseInput
            type="email"
            name="useremail"
            placeholder=""
            value={userEmail}
            className="border-[1.35px] border-[#818990] mt-[54.4px] py-[19px] pl-[21px] pr-[57px]"
            onChange={({ target }) => setUserEmail((target as HTMLInputElement).value)}
          />
          <BaseInput
            type="password"
            name="password"
            value={userPassword}
            placeholder=""
            className="border-[1.35px] border-[#818990] mt-[10.88px] py-[19px] pl-[21px] pr-[57px]"
            onChange={({ target }) => setUSerPassword((target as HTMLInputElement).value)}
          />
          <BaseButton
            type="submit"
            className="bg-[#2ACFBB] mt-[21.76px] pt-[19px] pb-[21px] text-white"
          >
            {strings.SigninButton}
          </BaseButton>
          <div className="mt-[13.6px] text-right">
            <span className="mr-[10px] text-[#818990] font-bold">
              {strings.Signin_PasswordResetText}
            </span>
            <button
              type="button"
              className="text-[16px] font-bold text-[#2ACFBB]"
              onClick={() => navigate.push(`/${strings.getLanguage()}/resetpassword`)}
            >
              {strings.Signin_PasswordResetButton}
            </button>
          </div>
        </form>
        <p className="mt-[68px] text-[#6C757B] font-bold">{strings.Signin_SocialLoginText}</p>
        <div className="mt-[43.52px] flex justify-around space-x-2">
          <SocialLogin authURL="https://mtm-test.apposter.com/api/auth/facebook?callbackURL=nft-marketplace">
            <img src={facebookLogo} alt="sns facebook logo" />
          </SocialLogin>
          <SocialLogin authURL="https://mtm-test.apposter.com/api/auth/google?callbackURL=nft-marketplace">
            <img src={googleLogo} alt="sns google logo" />
          </SocialLogin>
          <SocialLogin authURL="https://mtm-test.apposter.com/api/auth/line?callbackURL=nft-marketplace">
            <img src={lineLogo} alt="sns line logo" />
          </SocialLogin>
          <SocialLogin authURL="https://mtm-test.apposter.com/api/auth/kakao?callbackURL=nft-marketplace">
            <img src={kakaoLogo} alt="sns kakao logo" />
          </SocialLogin>
          <SocialLogin authURL="https://mtm-test.apposter.com/api/auth/apple?callbackURL=nft-marketplace">
            <img src={appleLogo} alt="sns apple logo" />
          </SocialLogin>
        </div>
        <p className="mt-[32.64px] text-[14px] text-[#6C757B]">
          {strings.Signin_SocialLogin}
          <a href="#/" className="underline">
            {strings.Terms_of_Use}
          </a>
          {strings.And}
          <a href="#/" className="underline">
            {strings.Privacy_Policy}
          </a>
          {strings.to} <br />
          {strings.You_agree}
        </p>
        <div className="mt-[67.77px] font-bold">
          <span className="inline-block mr-[10.84px] text-[#6C757B]">
            {strings.Signin_IshaveEmailText}
          </span>
          <Link
            to={`/${strings.getLanguage()}/signup`}
            className="text-[16px] font-bold text-[#2ACFBB]"
          >
            {strings.SignupButton}
          </Link>
        </div>
        <Loading />
      </div>
      {modalOpen && (
        <Modal
          close={() => setModalOpen(false)}
          outsideClick={() => setModalOpen(false)}
          header={modalMessage}
        />
      )}
    </section>
  );
}

export default SignIn;

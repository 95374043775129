import { useEffect, useState } from 'react';
import { strings } from '../../../utils/Localization/LocalizationStrings';
import Modal from '../../../components/Modal';
import { BannerParams } from '../../../types';
import { deleteBanner, getBanner, postBanner, updateBanner } from '../../../modules/API/BannerAPI';
import configs from '../../../constants/configs';
import ReservationModal from './ReservationModal';

interface BannerProps {
  position: BannerParams['position'];
  edit: boolean;
  reservation: boolean;
}

interface BannerItem extends Partial<Omit<BannerParams, 'position'>> {
  _id?: string;
}

const buttonModalDataList = {
  apply: strings.ApplyContentModal_Title,
  remove: strings.RemoveContentModal_Title,
};

const validateBanner = (banner: BannerItem): banner is BannerParams & { _id: string } =>
  !!banner.banner && !!banner.buttonText && !!banner.link && !!banner.subTitle && !!banner.title;

function Banner({ position, edit, reservation }: BannerProps) {
  const [modalOpen, setModalOpen] = useState(false);
  const [header, setHeader] = useState('');
  const [bannerItem, setBannerItem] = useState<BannerItem>({});
  const [imageError, setImageError] = useState(false);
  const [showReservationModal, setShowReservationModal] = useState(false);

  const updateBannerValue = (key: keyof BannerItem, value: string | File | null) =>
    setBannerItem({
      ...bannerItem,
      [key]: value,
    });

  const fetchBanner = async () => {
    const banners = await getBanner({
      draft: edit,
      position,
    });
    const bannerForPosition = banners[0];

    if (bannerForPosition == null) {
      setBannerItem({});
      return;
    }
    setBannerItem({
      ...bannerForPosition,
      banner: `${configs.REACT_APP_ENDPOINT_NFT}/${bannerForPosition.path}`,
    });
  };

  const clickButtonModal = (message: string) => {
    setHeader(message);
    setModalOpen(true);
  };

  const applyBanner = async ({
    draft = false,
    reservationTime,
  }: {
    reservationTime?: Date;
    draft?: boolean;
  } = {}) => {
    try {
      if (bannerItem == null || !validateBanner(bannerItem))
        throw new Error('정보를 모두 입력해 주세요.');
      if (bannerItem._id == null) {
        await postBanner({
          ...bannerItem,
          position,
          draft,
          reservationTime,
        });
      } else {
        await updateBanner(bannerItem._id, {
          ...bannerItem,
          position,
          draft,
          reservationTime,
        });
      }
      clickButtonModal(buttonModalDataList.apply);
      setBannerItem({});

      await fetchBanner();
    } catch (err) {
      let message = '에러가 발생했습니다.';
      if (err instanceof Error) {
        message = err.message;
      }
      clickButtonModal(message);
    }
  };

  useEffect(() => {
    fetchBanner();
  }, [edit, position]);

  return (
    <>
      {modalOpen && (
        <Modal
          close={() => setModalOpen(false)}
          outsideClick={() => setModalOpen(false)}
          header={header}
        />
      )}
      {reservation && showReservationModal && (
        <ReservationModal
          onClick={() => setShowReservationModal(false)}
          reservation={null}
          setReservation={(date) =>
            applyBanner({
              reservationTime: date,
            })
          }
        />
      )}
      <form
        onSubmit={async (e) => {
          e.preventDefault();
        }}
      >
        <div className="p-4">
          <p className="pl-2 inline font-medium">{strings.CommonBanner}</p>
          <input
            type="text"
            placeholder={strings.LanguageCodeInput}
            className="text-black placeholder:text-gray-200 border-1 relative left-14 h-8 rounded-xl shadow pl-2"
          />
          <div className="float-right">
            {edit && (
              <button
                type="button"
                className="font-bold px-7 py-2 mx-2 rounded-lg bg-gray-400 text-white"
                onClick={() => {
                  fetchBanner();
                }}
              >
                {strings.Cancel}
              </button>
            )}

            <button
              type="button"
              className="font-bold px-7 py-2 mx-2 rounded-lg bg-red-500 text-white"
              onClick={async () => {
                try {
                  if (bannerItem._id != null) {
                    await deleteBanner(bannerItem._id);
                  }
                  setBannerItem({});
                  clickButtonModal(buttonModalDataList.remove);
                } catch (err) {
                  let message = '에러가 발생했습니다.';
                  if (err instanceof Error) {
                    message = err.message;
                  }
                  clickButtonModal(message);
                }
              }}
            >
              {strings.Remove}
            </button>
            {edit && (
              <>
                <button
                  className="font-bold px-7 py-2 mx-2 rounded-lg bg-myColor text-white"
                  type="button"
                  onClick={() => applyBanner({ draft: true })}
                >
                  저장
                </button>
                <button
                  className="font-bold px-7 py-2 mx-2 rounded-lg bg-myColor text-white"
                  type="button"
                  onClick={() => {
                    if (reservation) {
                      setShowReservationModal(true);
                      return;
                    }
                    applyBanner({ reservationTime: new Date() });
                  }}
                >
                  {reservation ? '예약' : strings.Apply}
                </button>
              </>
            )}
          </div>
        </div>
        <ul>
          <li className="px-7 py-3  bg-white">
            <p className="flex ">
              <span>{strings.Image}</span>
              {edit && (
                <label htmlFor="uploadInput">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 0 24 24"
                    width="24px"
                    fill="#000000"
                    className="ml-[10px]"
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z" />
                  </svg>
                  <input
                    id="uploadInput"
                    type="file"
                    accept="image/*"
                    className="hidden"
                    onChange={({ currentTarget: { files } }) => {
                      if (files == null || files.length < 1) {
                        updateBannerValue('banner', null);
                        return;
                      }
                      updateBannerValue('banner', files[0]);
                    }}
                  />
                </label>
              )}
            </p>
            <div className="w-full bg-white border-b-2 mt-[20px]">
              {bannerItem?.banner == null || imageError ? (
                <input
                  type="text"
                  className="h-[30px] w-[530px] mb-2"
                  readOnly
                  value={bannerItem?.banner == null ? '' : '이미지를 불러올 수 없습니다.'}
                />
              ) : (
                <img
                  src={
                    typeof bannerItem.banner === 'object'
                      ? URL.createObjectURL(bannerItem.banner)
                      : bannerItem.banner
                  }
                  alt={strings.Image}
                  className="h-[49px] w-[530px] mb-2 object-cover"
                  onError={() => setImageError(true)}
                />
              )}
            </div>
          </li>
          <li className="px-7 py-3  bg-white">
            <span className="inline-flex float-left">{strings.Arrival_URL}</span>
            <input
              type="text"
              value={bannerItem?.link ?? ''}
              className="w-full h-7 bg-white border-b-2 mt-[10px]"
              onChange={(e) => {
                updateBannerValue('link', e.target.value);
              }}
              readOnly={!edit}
            />
          </li>
          <li className="px-7 py-3  bg-white">
            <span className="inline-flex float-left">{strings.Title_Text}</span>
            <input
              type="text"
              value={bannerItem?.title ?? ''}
              onChange={(e) => {
                updateBannerValue('title', e.target.value);
              }}
              className="w-full h-7 bg-white border-b-2  mt-[10px]"
              readOnly={!edit}
            />
          </li>
          <li className="px-7 py-3  bg-white">
            <span className="inline-flex float-left">{strings.Sub_Text}</span>
            <input
              type="text"
              value={bannerItem?.subTitle ?? ''}
              onChange={(e) => {
                updateBannerValue('subTitle', e.target.value);
              }}
              className="w-full h-7 bg-white border-b-2  mt-[10px]"
              readOnly={!edit}
            />
          </li>
          <li className="px-7 py-3  bg-white">
            <span className="inline-flex float-left">{strings.Button_Text}</span>
            <input
              type="text"
              value={bannerItem?.buttonText ?? ''}
              onChange={(e) => {
                updateBannerValue('buttonText', e.target.value);
              }}
              className="w-full h-7 bg-white border-b-2  mt-[10px]"
              readOnly={!edit}
            />
          </li>
        </ul>
      </form>
    </>
  );
}

export default Banner;

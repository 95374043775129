import { MenuList } from '../../../pages/AdminHome';

function Menu({
  selectedMenu,
  menus,
  setSelectedMenu,
}: {
  selectedMenu: MenuList;
  menus: MenuList[];
  setSelectedMenu: (menu: MenuList) => void;
}) {
  return (
    <ul className="py-6">
      {menus.map((title) => (
        <li
          className={` ${
            title === selectedMenu ? 'text-myColor' : ''
          }  flex items-center py-6 text-xl font-semibold hover:text-gray-300 border-b-2`}
          key={title}
        >
          <button onClick={() => setSelectedMenu(title)}>{title}</button>
        </li>
      ))}
    </ul>
  );
}

export default Menu;

import { UserWallet } from '../../types';
import { jsonFetch } from './APICall';
import { WALLET_ENDPOINT } from './APIEndpoints';

export const registerUserWallet = async (userId: string, walletAddress: string) => {
  const response = await await jsonFetch(WALLET_ENDPOINT, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      user: userId,
      walletAddress,
    }),
  });
  return response.data;
};

export const disconnectUserWallet = async (userId: string) => {
  const response = await jsonFetch(`${WALLET_ENDPOINT}/${userId}`, { method: 'DELETE' });
  return response.data;
};

export const getUserWallet = async (userId: string): Promise<UserWallet> => {
  const response = await jsonFetch(`${WALLET_ENDPOINT}/${userId}`);
  return response.data;
};

export const getWalletOwner = async (walletAddress: string): Promise<UserWallet> => {
  const response = await jsonFetch(`${WALLET_ENDPOINT}/wallet/${walletAddress}`);
  return response.data;
};

import { useContext, useEffect, useState } from 'react';
import { RouteChildrenProps, useHistory } from 'react-router-dom';
import { strings } from '../utils/Localization/LocalizationStrings';
import Modal from '../components/Modal';
import { getStudioWatch, postWatch } from '../modules/API/WatchAPI';
import UserinfoContext from '../context/UserinfoContext';
import { WatchFaceItem } from '../types';

import errorImage from '../assets/img/clearButtonIcon.svg';
import { parseThumbnail } from '../utils/stringUtils';
import useLoading from '../customHooks/useLoading';

function MintingWrite({ match }: RouteChildrenProps<{ id: string }>) {
  const [confirmCreate, setConfirmCreate] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [watchFace, setWatchFace] = useState<WatchFaceItem | null>(null);
  const { user } = useContext(UserinfoContext);
  const [Loading, toggle] = useLoading();

  const navigate = useHistory();

  const showConfirmCreateModalHandler = () => {
    setConfirmCreate(!confirmCreate);
  };

  useEffect(() => {
    (async () => {
      if (match == null) return;
      const {
        params: { id },
      } = match;
      try {
        toggle(true);
        const watchFaceaa = await getStudioWatch(id);
        setWatchFace(watchFaceaa);
      } catch {
        navigate.goBack();
      } finally {
        toggle(false);
      }
    })();
  }, []);

  const onConfirmCreateHandler = () => {
    navigate.push(`/${strings.getLanguage()}/userpage/${user?._id}`);
  };

  return (
    <div className="flex flex-col md:justify-around align-middle sm:mt-[105px] float-left w-full h-full max-md:z-[10001] max-md:px-2   max-md:bg-white max-md:overflow-y-scroll">
      {confirmCreate && (
        <Modal
          close={onConfirmCreateHandler}
          header={strings.CreateWatchModal_Title}
          outsideClick={showConfirmCreateModalHandler}
          buttonName={strings.Next}
        />
      )}
      {modalOpen && (
        <Modal
          close={() => setModalOpen(false)}
          header={strings.networkError}
          outsideClick={() => setModalOpen(false)}
        />
      )}

      <div className="flex sm:flex-row m-0 flex-col justify-center align-middle w-full">
        <div className="max-sm:mt-9">
          {watchFace?.model.preview ? (
            <img
              alt="watchface Icon"
              src={parseThumbnail(watchFace?.model.preview) || ''}
              onError={({ currentTarget }) => {
                // eslint-disable-next-line no-param-reassign
                currentTarget.src = errorImage;
              }}
              className="sm:w-[408px] sm:h-[408px] w-2/3 h-full sm:mr-10 m-auto"
            />
          ) : (
            <div className="sm:w-[408px] sm:h-[408px] w-2/3 mb-[100%] sm:mr-10 m-auto" />
          )}
        </div>
        <div className="flex flex-col items-start p-0 gap-3 sm:w-[665px] max-sm:px-5">
          <p className="font-semibold leading-[170%] text-[27px]">{watchFace?.appName}</p>
          <div className="float-left w-full ">
            <span className="leading-6 font-normal text-gray-700">Category</span>
            <span className="leading-6 font-medium text-black pl-[7px]">
              {watchFace?.category?.name}
            </span>
          </div>
          <div className="w-full border-[1px] border-[#F0F0F0] box-border rounded flex-none self-stretch h-32 resize-none p-2">
            {watchFace?.description}
          </div>
          <div className="w-full sm:mt-7">
            <div className="max-sm:flex-1 max-sm:flex-col max-sm:flex w-full gap-5 mb-5 md:text-right">
              <button
                className="bg-teal-400 rounded text-white sm:ml-5 px-4 py-3 "
                onClick={async () => {
                  if (match != null && user != null && watchFace != null) {
                    try {
                      await postWatch({
                        author: user._id,
                        category: watchFace.category?.name,
                        description: watchFace.description,
                        thumbnail: watchFace.model?.preview,
                        title: watchFace.appName,
                        appId: watchFace.appId,
                        model: watchFace.model,
                        version: watchFace.version,
                        device: watchFace.device,
                        os: watchFace.model.builder.description.osType,
                      });
                      showConfirmCreateModalHandler();
                    } catch (err) {
                      setModalOpen(true);
                    }
                  }
                }}
              >
                {strings.Create_Item}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Loading />
    </div>
  );
}

export default MintingWrite;

import configs from '../constants/configs';

export const emptyIfNill = (param: string | number | null | undefined) => param ?? '';

export const makeQuery = (data: { [key: string]: string | undefined | boolean }) =>
  Object.entries(data)

    .filter(([, value]) => value != null && value)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

export const ellipsisBetween = (str: string, length: number) =>
  str.length <= length * 2 ? str : `${str.slice(0, length)}...${str.slice(-length)}`;

export const relativeDateString = (date: Date | number) => {
  const parsedDate = new Date(date);
  let time = Math.floor((Date.now() - parsedDate.getTime()) / 1000);
  if (time < 60) return `${time} seconds ago`;
  time = Math.floor(time / 60);
  if (time < 60) return `${time} minutes ago`;
  time = Math.floor(time / 60);
  if (time < 24) return `${time} hours ago`;
  time = Math.floor(time / 24);
  if (time < 30) return `${time} days ago`;
  time = Math.floor(time / 30);
  if (time < 12) return `${time} months ago`;
  time = Math.floor(time / 12);
  return `${time} years ago`;
};

export const parseThumbnail = (thumbnail?: string | null) => {
  if (thumbnail == null) return null;
  return thumbnail.includes('://')
    ? thumbnail
    : new URL(`${configs.REACT_APP_ENDPOINT_WATCH}${thumbnail}`).toString();
};

export const formatDate = (targetDate: number | string | Date) => {
  const date = new Date(targetDate);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}년 ${month}월 ${day}일`;
};

export const formatDateTime = (targetDate: number | string | Date) => {
  const date = new Date(targetDate);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();
  return `${year}-${month}-${day}\n${hour}:${minute}:${second}`;
};

export default { emptyIfNill, makeQuery, ellipsisBetween };

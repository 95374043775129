import { useEffect, useRef, useState } from 'react';
import SelectArrow from '../../assets/img/Rectangle 1310.svg';
import SelectArrowDisabled from '../../assets/img/DropDownDisabled.svg';
import { strings } from '../../utils/Localization/LocalizationStrings';

interface Props {
  options: string[];
  selectedOption: string;
  textAlign: string;
  disabled?: string;
  setSelectOption: (value: string) => void;
}

function SelectBox({ options, selectedOption, textAlign, disabled, setSelectOption }: Props) {
  const [open, setOpen] = useState(false);
  const selectBoxRef = useRef<HTMLUListElement>(null);

  const handleClickOutSide = (e: MouseEvent) => {
    if (selectBoxRef.current && !selectBoxRef.current.contains(e.target as HTMLElement) && open) {
      setOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutSide);
    return () => {
      document.removeEventListener('mousedown', handleClickOutSide);
    };
  });
  return (
    <div className="flex">
      <div
        className={`${open ? 'hidden' : ''}
          ${textAlign}
          ${disabled} rounded pr-6 py-2 text-black leading-4 bg-white cursor-pointer flex-1 `}
        onClick={() => setOpen(!open)}
        role="presentation"
      >
        {selectedOption}
      </div>
      <img
        src={disabled ? SelectArrowDisabled : SelectArrow}
        alt={strings.ArrowIcon}
        className={`${open && 'rotate-180 '}`}
      />
      <ul
        role="presentation"
        className={`${open ? '' : 'hidden '}list-none bg-white rounded absolute w-full z-20`}
        ref={selectBoxRef}
      >
        {options.map((option: string) => (
          <li
            role="presentation"
            key={option}
            value={option}
            className={`${textAlign} pr-6 py-2 block bg-white rounded`}
            onClick={() => {
              setSelectOption(option);
              setOpen(false);
            }}
          >
            {option}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default SelectBox;

const SIGNING_TIMEOUT = 20000;

export const timeoutPromise = (promise: Promise<any>, timeout: number = SIGNING_TIMEOUT) =>
  Promise.race([
    promise,
    new Promise((resolve, reject) => {
      setTimeout(() => reject(new Error('signingTimeout')), timeout);
    }),
  ]);

export default { timeoutPromise };

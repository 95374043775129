import { WatchFaceItem, WatchFaceNFT, WatchFaceNFTCreation } from '../../types';
import {
  WATCHFACE_NFT_ENDPOINT,
  WATCH_ENDPOINT,
  LIST_PUBLISH_ENDPOINT,
  TIMEFLIK_WATCH,
} from './APIEndpoints';
import { makeQuery } from '../../utils/stringUtils';
import { jsonFetch } from './APICall';

const defaultHeader = {
  'Content-Type': 'application/json',
};

export const listPublishWatch = async (): Promise<WatchFaceItem[]> => {
  const headers = {
    ...defaultHeader,
    'X-User-Agent': 'mrtimemaker-mobile',
    'X-Instant-Token': localStorage.getItem('X-Instant-Token') ?? '',
  };

  const publishList = (await jsonFetch(
    `${LIST_PUBLISH_ENDPOINT}?isActive=true&platform=n&watchfaceType=sell`,
    {
      headers,
    }
  )) as { parentApiPath: string }[];

  const watchList = await Promise.all(
    publishList.map(({ parentApiPath }) =>
      jsonFetch(`${TIMEFLIK_WATCH}${parentApiPath}`, {
        headers,
      })
    )
  );

  return watchList.filter(({ version }) => version === 4);
};

export const getStudioWatch = async (appId: string): Promise<WatchFaceItem> =>
  jsonFetch(`${WATCH_ENDPOINT}/${appId}`, {
    headers: {
      ...defaultHeader,
      'X-User-Agent': 'mrtimemaker-mobile',
      'X-Instant-Token': localStorage.getItem('X-Instant-Token') ?? '',
    },
  });

export const postWatch = async (data: WatchFaceNFTCreation) =>
  jsonFetch(`${WATCHFACE_NFT_ENDPOINT}/`, {
    method: 'POST',
    headers: defaultHeader,
    body: JSON.stringify(data),
  });

export const getWatchList = async (
  query: {
    owner?: string;
    author?: string;
    hidden?: boolean;
  } = {}
): Promise<WatchFaceNFT[]> => {
  const response = await jsonFetch(`${WATCHFACE_NFT_ENDPOINT}?${makeQuery(query)}`);
  return response.data;
};

export const getWatchNFTInfo = async (id: string): Promise<WatchFaceNFT> => {
  const response = await jsonFetch(`${WATCHFACE_NFT_ENDPOINT}/${id}`);
  return response.data;
};

export const getWatchNFTInfoFromToken = async (tokenId: string): Promise<WatchFaceNFT> => {
  const response = await jsonFetch(`${WATCHFACE_NFT_ENDPOINT}/token/${tokenId}`);
  return response.data;
};

export const deleteWatchInfo = async (id: string): Promise<void> => {
  await jsonFetch(`${WATCHFACE_NFT_ENDPOINT}/${id}`, { method: 'DELETE' });
};

export const mintWatchNFT = async (
  id: string,
  mintBody: {
    owner: string;
    tokenId: number;
  }
): Promise<void> =>
  jsonFetch(`${WATCHFACE_NFT_ENDPOINT}/${id}/mint`, {
    method: 'PUT',
    headers: defaultHeader,
    body: JSON.stringify(mintBody),
  });

export const registerWatchNFTSale = async (
  id: string,
  requestBody: {
    owner: string;
    saleId: number;
    tokenId: number;
    price: number;
  }
): Promise<void> =>
  jsonFetch(`${WATCHFACE_NFT_ENDPOINT}/${id}/sale`, {
    method: 'PUT',
    headers: defaultHeader,
    body: JSON.stringify(requestBody),
  });

export const closeWatchNFTSale = async (
  id: string,
  requestBody: { action: 'sold' | 'canceled'; owner?: string }
): Promise<void> =>
  jsonFetch(`${WATCHFACE_NFT_ENDPOINT}/${id}/close`, {
    method: 'PUT',
    headers: defaultHeader,
    body: JSON.stringify(requestBody),
  });

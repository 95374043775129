import { ReportedItem } from '../../types';
import { strings } from './LocalizationStrings';

const ReportedReasons: Record<ReportedItem['reason'][number], string> = {
  slander: strings.Report_Slander,
  porn: strings.Report_Pornography,
  swear: strings.Report_Abuse,
  others: strings.Report_Etc,
};

export default ReportedReasons;

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { WagmiConfig } from 'wagmi';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './modules/providers/ipfsClient';
import { wagmiClient } from './connection/walletConnect';

ReactDOM.render(
  <BrowserRouter>
    <WagmiConfig client={wagmiClient}>
      <App />
    </WagmiConfig>
  </BrowserRouter>,
  document.getElementById('root')
);

reportWebVitals();

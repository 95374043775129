import { twMerge } from 'tailwind-merge';
import { Banner } from '../types';
import configs from '../constants/configs';

const DEFAULT_BANNER_CLASS =
  'w-full h-[60px] md:h-[120px] px-[60px] md:pl-[121.71px] md:pr-[138px] relative flex justify-between items-center rounded-[4px] overflow-hidden';
const DEFAULT_TITLE_CLASS = 'text-[11px] md:text-[17.84px] text-white';
const DEFAULT_SUBTITLE_CLASS = 'text-[16px] md:text-[22.84px] text-white';

function BaseBanner({
  className,
  banner: { path, subTitle, title, buttonText, link },
  titleClass,
  subTitleClass,
}: {
  banner: Banner;
  className?: string;
  titleClass?: string;
  subTitleClass?: string;
}) {
  return (
    <div
      className={twMerge(DEFAULT_BANNER_CLASS, className)}
      style={{
        backgroundImage: `url(https://${configs.REACT_APP_S3_BUCKET}/${path})`,
      }}
    >
      <div>
        <p className={twMerge(DEFAULT_TITLE_CLASS, titleClass)}>{title}</p>
        <strong className={twMerge(DEFAULT_SUBTITLE_CLASS, subTitleClass)}>{subTitle}</strong>
      </div>
      <a
        href={link}
        target={link.includes('https://') || link.includes('http://') ? '_blank' : '_self'}
        className="w-[173px] h-[48px] bg-[#2ACFBB] rounded-[4px] text-white text-center leading-[48px] max-md:hidden"
        rel="noreferrer"
      >
        {buttonText} &gt;
      </a>
    </div>
  );
}

export default BaseBanner;

import { createPortal } from 'react-dom';
import { useRef } from 'react';
import { strings } from '../utils/Localization/LocalizationStrings';
import BaseButton from './BaseButton';

interface modalProps {
  onConfirm: () => void;
  onClose: () => void;
}

const ReverifyEmailModal = ({ onConfirm, onClose }: modalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);
  return createPortal(
    <>
      <div
        className="fixed top-0 left-0 w-full h-screen bg-black opacity-20"
        onClick={onClose}
        ref={modalRef}
        role="presentation"
      />
      <div className="w-[296px] h-[138px] z-10 bg-white pt-[20px] pb-[16px] px-[16px] fixed top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] text-center rounded-[8px]">
        <p>{strings.EmailResetTerms} </p>
        <div className="mt-[28px]">
          <BaseButton
            type="button"
            className="w-[126px] h-[48px] mr-[12px] bg-[#E9EAEC]"
            onClick={onClose}
          >
            {strings.Go_Back}
          </BaseButton>
          <BaseButton
            type="button"
            className="w-[126px] h-[48px] bg-[#2ACFBB] text-white"
            onClick={onConfirm}
          >
            {strings.Next}
          </BaseButton>
        </div>
      </div>
    </>,
    document.getElementById('modal') as HTMLElement
  );
};

export default ReverifyEmailModal;

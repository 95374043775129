import { Contract } from 'ethers';
import { jsonFetch } from '../API/APICall';
import { WatchFaceNFTToken } from '../../types';

const IPFS_ENDPOINT = 'https://timeflik.infura-ipfs.io/ipfs/';

export const getEventArg = (
  events: { event: string; args: Record<string, any> }[],
  eventName: string,
  arg: string
) => {
  const targetEvent = events.find(({ event }: { event: string }) => event === eventName);
  if (!targetEvent) return null;
  const {
    args: { [arg]: value },
  } = targetEvent;
  return value;
};

export default class NFTTransactions {
  constructor(
    private _contracts?: { nftMarketContract: Contract; nftContract: Contract },
    private _address?: string
  ) {
    this._contracts = _contracts;
    this._address = _address;
  }

  protected get contracts() {
    if (this._contracts == null) throw new Error('Contracts not set');
    return this._contracts;
  }

  protected get address() {
    if (this._address == null) throw new Error('Address not set');
    return this._address;
  }

  getTokenInfo = async (id: number): Promise<WatchFaceNFTToken> => {
    const hash = await this.contracts.nftContract.tokenURI(id);
    return jsonFetch(`${IPFS_ENDPOINT}${hash}`);
  };
}

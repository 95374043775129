/* eslint-disable camelcase */
import BigNumber from 'bignumber.js';
import { strings } from '../../utils/Localization/LocalizationStrings';
import ipfs from '../providers/ipfsClient';
import { WatchFaceNFTToken } from '../../types';
import NFTTransactions, { getEventArg } from './NFTTransactions';
import addresses from '../../constants/addresses';

const addNFT = async ({ watchId, builder, author, unlockableContent }: WatchFaceNFTToken) => {
  return ipfs.add(
    JSON.stringify({
      watchId,
      unlockableContent,
      author,
      builder,
    })
  );
};

export default class TokenTransactions extends NFTTransactions {
  getIsApproved = async () =>
    this.contracts.nftContract.isApprovedForAll(this.address, addresses.nftMarket);

  setApprovalForAll = async () =>
    this.contracts.nftContract.setApprovalForAll(addresses.nftMarket, true, {
      from: this.address,
    });

  mintNFT = async (...nftParams: Parameters<typeof addNFT>): Promise<number> => {
    const metadataAdded = await addNFT(...nftParams);
    if (!metadataAdded) throw new Error(strings.mintingError);
    const mintRequest = await this.contracts.nftContract.mintNFT(metadataAdded.path, {
      from: this.address,
    });

    const { events } = await mintRequest.wait();

    const tokenId = getEventArg(events, 'Transfer', 'tokenId') as BigNumber;

    return tokenId.toNumber();
  };

  removeNFT = (tokenId: number) =>
    this.contracts.nftContract.removeNFT(tokenId, { from: this.address });
}

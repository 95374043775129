import { useState } from 'react';
import { strings } from '../../utils/Localization/LocalizationStrings';
import icnRightChevron from '../../assets/img/icn_chevron_right.svg';
import icnDelete from '../../assets/img/icn_delete.svg';
import { BannerItem } from '../../types';

function MultiBanner({
  datas: { banner, link, subTitle, title, buttonText },
  onRemove,
  dataUpdater,
  index,
  readOnly,
}: {
  datas: Partial<BannerItem>;
  index: number;
  onRemove: (index: number) => void;
  dataUpdater: <Key extends keyof BannerItem>(key: Key, value: BannerItem[Key]) => void;
  readOnly: boolean;
}) {
  const [imageError, setImageError] = useState(false);
  return (
    <div className="w-[274px] shadow rounded-md mr-5 flex-none" key={index}>
      <div>
        <button type="button" className="p-3">
          <img src={icnRightChevron} alt="오른쪽 화살표 아이콘" />
        </button>
        <button type="button" className="p-3" onClick={() => onRemove(index)}>
          <img src={icnDelete} alt="지우기 아이콘" />
        </button>
      </div>
      <form>
        <ul>
          <li className="px-7 pt-3  bg-white">
            <div className="flex">
              <span className="text-[13px]">{strings.Image}</span>
              {!readOnly && (
                <label className="cursor-pointer" htmlFor={`uploadFile${index}`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 0 24 24"
                    width="24px"
                    fill="#000000"
                    className="ml-4"
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z" />
                  </svg>
                  <input
                    id={`uploadFile${index}`}
                    type="file"
                    accept="image/*"
                    className="hidden"
                    onChange={({ currentTarget: { files } }) => {
                      if (files == null || files.length < 1) return;
                      dataUpdater('banner', files[0]);
                    }}
                  />
                </label>
              )}
            </div>
            <div className="w-full bg-white border-b-2 mt-[20px]">
              {banner == null || imageError ? (
                <input
                  type="text"
                  className="h-[30px] mb-1 w-full text-[15px]"
                  readOnly
                  value={banner == null ? '' : '이미지를 불러올 수 없습니다.'}
                />
              ) : (
                <img
                  src={typeof banner === 'object' ? URL.createObjectURL(banner) : banner}
                  alt={strings.Image}
                  className="h-[30px] w-[239px] mb-2"
                  onError={() => setImageError(true)}
                />
              )}
            </div>
          </li>
          <li className="px-7 pt-3  bg-white">
            <span className="inline-flex float-left text-[13px]">{strings.Arrival_URL}</span>
            <div className="border-b-2">
              <input
                type="text"
                value={link}
                className="w-full h-7 bg-white my-2 text-[15px]"
                onChange={(e) => {
                  dataUpdater('link', e.target.value);
                }}
                readOnly={readOnly}
              />
            </div>
          </li>
          <li className="px-7 pt-3  bg-white">
            <span className="inline-flex float-left text-[13px]">{strings.Title_Text}</span>
            <div className="border-b-2">
              <input
                type="text"
                value={title}
                onChange={(e) => {
                  dataUpdater('title', e.target.value);
                }}
                className="w-full h-7 bg-white my-2 text-[15px]"
                readOnly={readOnly}
              />
            </div>
          </li>
          <li className="px-7 pt-3  bg-white">
            <span className="inline-flex float-left text-[13px]">{strings.Sub_Text}</span>
            <div className="border-b-2">
              <input
                type="text"
                value={subTitle}
                onChange={(e) => {
                  dataUpdater('subTitle', e.target.value);
                }}
                className="w-full h-7 bg-white my-2 text-[15px]"
                readOnly={readOnly}
              />
            </div>
          </li>
          <li className="px-7 pt-3  bg-white">
            <span className="inline-flex float-left text-[13px]">{strings.Button_Text}</span>
            <div className="border-b-2">
              <input
                type="text"
                value={buttonText}
                onChange={(e) => {
                  dataUpdater('buttonText', e.target.value);
                }}
                className="w-full h-7 bg-white my-2 text-[15px]"
                readOnly={readOnly}
              />
            </div>
          </li>
        </ul>
      </form>
    </div>
  );
}

export default MultiBanner;
